(function (window, angular) {
	/**
	 * Atentie: ngClick se executa de doua ori: o data pt. span si o data pt. input
	 */
	function template($attrs) {
		return `<label class="mt-checkbox k-checkbox"> <!--style="margin-bottom: 0;" -->
							<input type="checkbox"
							 	 ng-model="$ctrl.ngModel"
							 	 ${$attrs.ngChange ? 'ng-change="$ctrl.ngChange()"' : ''}
							 	 ${$attrs.ngReadonly ? 'ng-readonly="$ctrl.ngReadonly()"' : ''}
							 	 ${$attrs.ngDisabled ? 'ng-disabled="$ctrl.ngDisabled()"' : ''}
							  	 cast-to-boolean/>
							<ng-transclude></ng-transclude>
							<span></span>
						</label>`;
	}

	class controller {}

	var module = angular.module('kv');
	module.component('mtCheckbox',
		{
			bindings: {
				ngModel: '=',
				ngChange: '&',
				ngDisabled: '&',
				ngReadonly: '&',
			},
			// template: template,
			template: template,
			controller: controller,
			transclude: true
		});
	template.$inject = ['$attrs'];

})(window, angular);
