kv.controller('taskEditCtrl', function ($uibModalInstance, params, $scope, $rootScope, resource, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'task', 'task_id', $scope, params);
	if (!params.task_id) {
		vm.data = params;
		vm.data.all_day = true;
		vm.data.users = [];
		vm.data.users.push({
			id: API.user.user_id,
			name: API.user.full_name
		});

		if(params.project) {
			vm.data.project_id = params.project.project_id;
			vm.data.project = {
				project_id: params.project.project_id,
				project_name: params.project.project_name
			};
			vm.data.customer_id = params.customer.customer_id;
			vm.data.customer = {
				customer_id: params.customer.customer_id,
				customer_name: params.customer.customer_name
			};
		}

		if(params.legal_case){
			vm.data.legal_case_id = params.legal_case.legal_case_id;
			vm.data.customer_id = params.legal_case.customer_id;
			vm.data.customer = {
				customer_id: params.legal_case.customer_id,
				customer_name: params.legal_case.customer_name
			};
		}


		let currentDate = new Date();
		let day = currentDate.getDate();
		let month = currentDate.getMonth() + 1;
		let year = currentDate.getFullYear();

		if (!params.start_date) vm.data.start_date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
		if (!params.end_date) vm.data.end_date =  year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' ' + vm.API.END_DAY_AT;
		vm.data.start_date = new dateClass(vm.data.start_date);
		vm.data.end_date = new dateClass(vm.data.end_date);
		// vm.data.deadline = new dateClass(vm.data.end_date);
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		let rest = vm.getInjection('resource').init('task');
		rest.get({id: params.task_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) {
				vm.data = response;
				vm.data.start_date = new dateClass(vm.data.start_date);
				vm.data.end_date = new dateClass(vm.data.end_date);
				if (vm.data.users) {
					angular.forEach(vm.data.users, function (v, i) {
						vm.data.users[i].id = v.user_id;
						vm.data.users[i].name = v.full_name;
					});
				}
			} else {
				console.error(response);
			}
		});
		this.entityId = params.task_id ? params.task_id : vm.data.task_id ;
		// get files for current EntityID
	    // listen for the event of 'document_storage_loaded' so we know when the child component has loaded
	    // then we trigger the load_files method
	    $scope.$on("document_storage_loaded", () => {
		   $rootScope.$broadcast("load_files", 'task', this.entityId);
		});

	}

	vm.newEntity = function newEntity(entity_type) {
		kvUtils.newRelatedModelEntityFor(entity_type, vm.data);
	};

	vm.getQueryParam = function (param, param_value) {
		return "&" + param + "=" + param_value;
	};

	vm.addTaskToTimesheet = function(entity){
		let url = 'timesheet/?create_from=task&user_id=' + vm.API.user.user_id;
		if(entity.customer){
			url += vm.getQueryParam("customer_id", entity.customer.customer_id);
			url += vm.getQueryParam("customer_name", entity.customer.customer_name);
		}

		if(entity.project){
			url += vm.getQueryParam("project_id", entity.project.project_id);
			url += vm.getQueryParam("project_name", entity.project.project_name);
		}

		if (entity.project && entity.project.customer_fiscal_entity && entity.project.customer_fiscal_entity.customer_fiscal_entity_ext.communication_language) {
			url += vm.getQueryParam("customer_language", entity.project.customer_fiscal_entity.customer_fiscal_entity_ext.communication_language);
		}


		if(entity.reference){
			url += vm.getQueryParam("reference_id", entity.reference.project_id);
			url += vm.getQueryParam("reference_name", entity.reference.project_name);
		}


		if(entity.task_name){
			if(entity.description == null) {
				url += vm.getQueryParam("comments", entity.task_name);
			}else{
				url += vm.getQueryParam("comments", entity.description);
			}
		}
		if(entity.start_date){
			let currentDate = new Date();
			let day = currentDate.getDate();
			let month = currentDate.getMonth() + 1;
			let year = currentDate.getFullYear();
			entity.start_date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;

			url += vm.getQueryParam("start_date", entity.start_date);
		}

		//Ioana Gubavu 11-08-2020 -- commented in order to see if necessary
		/*if(API.masterPractice.master_practice_id){
			url += vm.getQueryParam('practice_id', API.masterPractice.master_practice_id);
			url += vm.getQueryParam('practice_name', API.masterPractice.master_practice_name);
		}else{
			url += vm.getQueryParam('practice_name',' - ');
		}*/

		window.location.href = vm.API.URL + url;
	};

	vm.changeStatus = function(entity){
		entity.done = !entity.done;
		let rest = vm.getInjection('resource').init('task');
		rest.update({
			id: entity.task_id,
			params: {
				action: (entity.done ? 'markCompleted' : 'markToDo')
			}
		}).then(function (response) {
			$uibModalInstance.close({response: response});
		});
	};

	vm.deleteTask = function deleteTask(task_id) {
		vm.confirm("DELETE", vm.trans('ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			vm.entity = 'task';
			var REST = resource.init(vm.entity);
			REST.delete({
				params: {
					id: task_id
				}
			}).then(function (response) {
				if(response.error && response.error.error_code){
					toastr.error((response.error.message + ". </br> " + response.error.details));
				}else{
					window.location.reload();
				}
			});
		});
	};

	/**
	 * Save task
	 */
	vm.prepareDataToSave = function () {
		let dataToSave = angular.copy(vm.data);
		if (vm.data.all_day) {
			dataToSave.start_date = vm.data.start_date.DateFormat + " " + vm.API.START_DAY_AT;
			dataToSave.end_date = vm.data.start_date.DateFormat + " " + vm.API.END_DAY_AT;
		} else {
			dataToSave.start_date = vm.data.start_date.PostDatetime;
			dataToSave.end_date = vm.data.end_date.PostDatetime;
		}
		if(dataToSave.task_id){
			delete dataToSave.created_at;
		}
		return dataToSave;
	};

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;

		if(API.tenant.short_name === 'SIMIONBACIU' && vm.data.customer_id != null) {
			if (vm.data.project_id === null || typeof vm.data.project_id == 'undefined') {
				$('[ng-model="vm.data.project_id"] .select2-selection--single').css("border", "1px solid red");
				if (!vm.custom_errors) vm.custom_errors = [];
				vm.custom_errors.push(vm.trans('LANG.PROJECT_IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.project_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}
			if (vm.data.reference_id === null || typeof vm.data.reference_id == 'undefined') {
				$('[ng-model="vm.data.reference_id"] .select2-selection--single').css("border", "1px solid red");
				if (!vm.custom_errors) vm.custom_errors = [];
				vm.custom_errors.push(vm.trans('LANG.REFERENCE_IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.reference_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}
		}

		if(vm.has_error){
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}

	};

	return vm;
});
