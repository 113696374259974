kv.controller('legalCaseConfigurationSettingsCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'tenant', 'tenant_id', $scope, params);

    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });
    var REST = resource.init('tenant');

    REST.get({
        url: "tenant/settings"
    }).then(function (data) {
        KApp.unblock('.modal-content');
        vm.data = data;
    });

    vm.saveSettings = function () {
        let postData = angular.copy(vm.data);
        vm.rest.post({
            url: `tenant/settings`,
            data: postData
        }).then(function (response) {
            if (response.status || response.status == undefined) {
                vm.close();
            }
        });
    };

    return vm;
});
