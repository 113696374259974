kv.controller('ProfileCtrl', function ($scope, $element, $attrs, $injector, resource) {

	var vm = new baseCtrl($scope, $element, $attrs, $injector);
	vm.primaryKey = 'user_id';
	vm.entity = 'user';
	var REST = resource.init(vm.entity);
	KApp.block($element);

	function getData() {
		REST.get({
			url:"profile"
		}).then(response => {
			KApp.unblock($element);
			if (response.status == false) return vm.showErrorMessages(response);
			vm.dataHasLoaded = true;
			vm.data = response;
			vm.data.croppedAvatar = "";
			vm.data.originalAvatar = "";
		});
	}

	vm.data = getData();

	// used for displaying the error message
	$scope.passwordError = false;
	$scope.validationError = false;

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;

		if(emailIsValid(vm.data.email) === false){
			$('[ng-model="vm.data.email"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.EMAIL_IS_INVALID'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.email"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		if(vm.has_error){
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}

	};


	vm.resetPassword = function () {
		let postData = angular.copy(vm.data);
		let rest = vm.getInjection('resource').init('user');
		// expression for password to contain a capital letter
		var containsCapitalLetter = new RegExp("^(?=.*[A-Z])");
		//expression for password to contain a number
		var containsNumber = new RegExp("(?=.*[0-9])");
		error = false;
		old_password_error = false;

		// if tenant password policy is FORCE_STRONG PASSWORD
		if (postData.new_password) {
			if (vm.data.tenant.tenant_ext.password_policy == "FORCE_STRONG_PASSWORD") {
				// if one of the passwords are set...
				if (postData.new_password || postData.confirm_password) {
					// ...verify password length
					lengthError = !(postData.new_password && (postData.new_password.length >= 8));

					// ...verify password to contain a capital letter
					if (containsCapitalLetter.test(postData.new_password)) {
						containsCapitalLetterError = false;
					} else {
						containsCapitalLetterError = true;
					}

					// ...verify password to contain a number
					if (containsNumber.test(postData.new_password)) {
						containsNumberError = false;
					} else {
						containsNumberError = true;
					}

					// if password ok (no error from above), verify if password is equal to confirm_password
					if (!lengthError && !containsCapitalLetterError && !containsNumberError) {
						if (postData.new_password == postData.confirm_password) {
							error = false;
							$('[ng-model="vm.data.new_password"] input, [ng-model="vm.data.new_password"]').css("border", "1px solid #c2cad8");
							$('[ng-model="vm.data.confirm_password"] input, [ng-model="vm.data.confirm_password"]').css("border", "1px solid #c2cad8");
						} else {
							error = true;
							$scope.validationError = true;
							$('[ng-model="vm.data.new_password"] input, [ng-model="vm.data.new_password"]').css("border", "1px solid red");
							$('[ng-model="vm.data.confirm_password"] input, [ng-model="vm.data.confirm_password"]').css("border", "1px solid red");
						}
						if (!postData.old_password) {
							error = true;
							$scope.validationError = true;
							$('[ng-model="vm.data.old_password"] input, [ng-model="vm.data.old_password"]').css("border", "1px solid red");
						}
						$scope.passwordError = false;
					} else {
						error = true;
						$scope.passwordError = true;
						$('[ng-model="vm.data.new_password"] input, [ng-model="vm.data.new_password"]').css("border", "1px solid red");
					}
				}
				// if tenant password policy is STANDARD, just verify the two passwords
			} else {
				if (postData.new_password == postData.confirm_password) {
					error = false;
					$('[ng-model="vm.data.new_password"] input, [ng-model="vm.data.new_password"]').css("border", "1px solid #c2cad8");
					$('[ng-model="vm.data.confirm_password"] input, [ng-model="vm.data.confirm_password"]').css("border", "1px solid #c2cad8");
				} else {
					error = true;
					$scope.validationError = true;
					$('[ng-model="vm.data.new_password"] input, [ng-model="vm.data.new_password"]').css("border", "1px solid red");
					$('[ng-model="vm.data.confirm_password"] input, [ng-model="vm.data._confirm_password"]').css("border", "1px solid red");
				}
				if (!postData.old_password) {
					error = true;
					$scope.validationError = true;
					$('[ng-model="vm.data.old_password"] input, [ng-model="vm.data.old_password"]').css("border", "1px solid red");
				}
			}
		}

		// check if field was filled with data
		if (vm.data.old_password && vm.data.old_password.length) {
			old_password_error = false;
		} else {
			old_password_error = true;
		}

		// if field is not empty, make request
		if (error === false && old_password_error === false) {
			var resetPasswordData = {
				'old_password' : postData.old_password,
				'password' : postData.new_password,
			};
			rest.post({
				url: "profile/changePassword",
				id: vm.data.user_id,
				data: resetPasswordData
			}).then((response) => {
				if(response.status == true){
					toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'), {timeOut: 2000});
					setTimeout(function(){
						window.location.href =  window.origin + '/auth/logout';
					}, 1000);
				}else{
					vm.alert('ALERT', vm.trans(response.data.error));
				}
			});
		}
	};



	function emailIsValid (email) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	}

	vm.save = function () {
		error = false;
		var postData = angular.copy(vm.data);
		delete postData.user_user;
		delete postData.user_practice;


		// if no error, update profile
		if (!error) {
			REST.update({
				url: 'profile',
				data: postData
			}).then(function () {
				window.location = '/profile';
			});
		}
	};

	return vm;
});
