kv.controller('userEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'user_id';
	vm.entity = 'user';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.avatarChanged = false;
	vm.max_user_count = parseInt(vm.bladeParams.maxUserCount);
	vm.active_user_count = parseInt(vm.bladeParams.activeUserCount);
	vm.showAlertMaxUserCountMsg = "LANG.NO_MORE_USER_ALLOWED";


	var REST = resource.init(vm.entity);
	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];
	//
	//
	// vm.closeAvatar = function () {
	// 	vm.avatarChanged = true; //pt. refresh avatar
	// 	$('user-avatar').closest('.dropdown-menu').hide();
	// 	vm.getInjection('$timeout')(() => vm.avatarChanged = false);
	// };

	if (page_identifier == 'edit') {
		vm.isNewRecord = false;
		$http.get("/user/" + page_entity_id).then(function (response) {
			vm.data = response.data;
			if(vm.data.password) {
				delete vm.data.password;
			}

			if(!vm.data.language) {
				vm.data.language = 'en';
			}

			vm.data.oldEmail = vm.data.email;
			vm.data.croppedAvatar = "";
			vm.data.originalAvatar = "";
			if (vm.data.user_practice) {
				angular.forEach(vm.data.user_practice, function (v, i) {
					vm.data.user_practice[i].id = v.practice_id;
					vm.data.user_practice[i].name = v.practice.practice_name;
				});
			}

			if (vm.data.user_tenant_fiscal_entity) {
				angular.forEach(vm.data.user_tenant_fiscal_entity, function (v, i) {
					vm.data.user_tenant_fiscal_entity[i].id = v.tenant_fiscal_entity_id;
					vm.data.user_tenant_fiscal_entity[i].name = v.tenant_fiscal_entity.tenant_fiscal_entity_name;
				});
			}

			if (vm.data.people_group) {
				angular.forEach(vm.data.people_group, function (v, i) {
					vm.data.people_group[i].id = v.group_id;
					vm.data.people_group[i].name = v.group.group_name;
				});
			}

			if (vm.data.user_security_group) {
				angular.forEach(vm.data.user_security_group, function (v, i) {
					vm.data.user_security_group[i].id = v.security_group_id;
					vm.data.user_security_group[i].name = v.security_group.name;
				});
			}
			vm.data.action = 'edit';
			$scope.$broadcast('dataLoaded');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		let defaultObjects = JSON.parse(vm.bladeParams.defaultObjects);
		vm.data = defaultObjects;
		vm.data.action = 'create';
	}

	vm.newEntity = function newEntity(entity) {
		if (!vm.data[entity]) vm.data[entity] = [];
		vm.data[entity].push({});
	};

	vm.setAllToSecondary = function ($index, entity) {
		angular.forEach(vm.data[entity], function (value, key) {
			if ($index !== key) {
				value.is_primary = 0;
			}
		});
	};

	vm.deleteEntity = function deleteEntity($index, entity_type, id) {
		if (!id) {
			return vm.data[entity_type].splice($index, 1);
		}

		if (page_entity_id) {
			REST.delete({
				url: vm.entity + '/:id/' + entity_type + '/:entityId',
				params: {
					id: page_entity_id,
					entityId: id
				}
			}).then(function (data) {
				vm.data[entity_type].splice($index, 1);
			});
		}
	};

	// check if exists another user with the same email
	vm.checkEmailExistence = function (email) {
		var REST = resource.init('user');

		// check if field was filled with data
		if (vm.data.email && vm.data.email.length) {
			error = false;
		} else {
			error = true;
			vm.email_exists = false;
			vm.email_valid = false;
		}

		// if field is not empty, make request
		if (!error) {
			// variable for display success alert
			vm.email_valid = false;
			REST.list({
				params: {
					email: email
				}
			}).then(function (res) {
				if (res.data.length) {
					vm.email_exists = true;
					vm.email_valid = false;
				} else {
					vm.email_exists = false;
					vm.email_valid = true;
				}
			});
		}
	};

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		if(postData.password === ""){
			delete postData.password;
		}

		return postData;
	};


	// function addPeopleGroup() {
	//     if (!vm.result.people_group) {
	//         vm.result.people_group = [];
	//     }
	//     vm.result.people_group.push({});
	// }
	return vm;
});
