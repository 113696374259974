kv.controller('MoveCustomerFiscalEntityOnAnotherCustomerCtrl', function ($uibModalInstance, data, saveCallback, $scope, $filter, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'customer_fiscal_entity', 'customer_fiscal_entity_id', $scope, data);
	if(data) {
		vm.data = {
			customer_fiscal_entity_id: data.customer_fiscal_entity_id,
			linkedEntities: data.linkedEntities,
		};

		console.log(vm.data);

	}
	vm.cancel = function () {
		$uibModalInstance.dismiss('close');
	};

	vm.save = function () {
		vm.confirm('Confirm', $filter('trans')('LANG.ARE_YOU_SURE')).then(response => {
			if (response == 'cancel') return;

			let REST = this.getInjection('resource').init("customer_fiscal_entity");
			let ctrl = this;
			REST.post({
				url: 'customer_fiscal_entity/' + vm.data.customer_fiscal_entity_id + '/actions/change_customer',
				params: {
					customer_id: vm.data.new_customer_id
				}
			}).then(function (res) {
				$uibModalInstance.dismiss('close');
			});
		});
	};
	vm.saveCallback = saveCallback;

	return vm;
});
