kv.controller('renameFileCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'file_storage', 'id', $scope, params);
	vm.file_name = params.file_name;
	vm.save = function () {
		KApp.block('#documents_storage_file-name');
		vm.rest.update({
			url: 'files/' + params.entity + '/' + params.entity_id + '/rename_file/' + params.file_id,
			data: {
				parent_id: params.parent_id,
				entity_id : params.entity_id,
				file_name: vm.file_name,
				file_id: params.file_id
			}
		}).then(function(response){
			KApp.unblock('#documents_storage_file-name');
			vm.saveCallback(response, vm.modal);
		});
	};

	vm.saveCallback = saveCallback;
	return vm;
});
