//blade = resources/views/contact/contact-view.blade.php
kv.controller('userViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'user_id';
	vm.entity = 'user';
	vm.entityType = 'user';
	vm.entityId = vm.bladeParams.contactId;
	vm.user = JSON.parse(vm.bladeParams.user);
	vm.bladeParams.maxUserCount = parseInt(vm.bladeParams.maxUserCount);
	vm.bladeParams.activeUserCount = parseInt(vm.bladeParams.activeUserCount);
	vm.groups = {};
	vm.rest = vm.getInjection('resource').init('user');

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];


	vm.commentsConfig = {
		entity_type: vm.entity
	};

	$(document).ready(function () {
		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			$(e.target.hash).find('.chart').each(function () {
				if ($(this).highcharts()) {
					$(this).highcharts().reflow();
				}
			});
		});
	});

	vm.past7DaysActivities = {
		template: 'activities',
		url: API.URL + "activity/activities_with_contact",
		filter: {
			user_id: page_entity_id,
			range: 'last 7 days',
		},
		title: vm.trans('ACTIVITY_HISTORY_LAST_7_DAYS'),
	};

	/**
	 * change user status. If user is active, show modal to replace user activities
	 */
	vm.changeUserActiveStatus = function () {
		let postData = {};
		postData.active = vm.user.active ? 0 : 1;
		postData.changeActiveStatus = true;
		KApp.block('.changeUserActiveStatus');
		if(vm.user.active){
			vm.replaceUserActivity(postData);
		} else {
			vm.changeStatusUser(postData);
		}
	};

	/**
	 * force password change
	 */
	vm.forceUserToChangePassword = function () {
		let rest = vm.getInjection('resource').init('user');
		KApp.block('.forceUserToChangePassword');
		rest.post({
			url: 'user/' + vm.user.user_id + '/actions/force-password-change',
		}).then((response) => {
			if (response.status === false && response.error) {
				vm.alert('ALERT', vm.trans('LANG.ACTIVE_STATUS_NOT_CHANGE'));
			} else {
				// display message
				toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
			}
		})
		.catch(function (error) {
			// Push a general error message to vm.errors array
			toastr.error(vm.trans('LANG.ERROR_WHILE_SAVING'));
		})
		.finally(function () {
			KApp.unblock('.forceUserToChangePassword');
		});
	};

	/**
	 * change user status - enable/disable
	 *
	 * @param postData
	 */
	vm.changeStatusUser = function(postData) {
		let rest = vm.getInjection('resource').init('user');
		rest.post({
			url: 'user/' + vm.user.user_id + '/actions/' + ((postData.active === 0 ) ? 'disable' : 'enable')
		}).then((response) => {
			if (response.status === false && response.error) {
				vm.alert('ALERT', vm.trans('LANG.ACTIVE_STATUS_NOT_CHANGE'));
			} else {
				// update vm
				vm.user.active = response.data.active;

				// update active user count
				vm.bladeParams.activeUserCount = response.subscription.active_users;

				toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
			}
		})
		.catch(function (error) {
			toastr.error(vm.trans('LANG.ERROR_WHILE_SAVING'));
		})
		.finally(function () {
			KApp.unblock('.changeUserActiveStatus');
			window.location.reload();
		});
	};

	/**
	 * display screen to choose to replace user activities
	 * @param postData
	 */
	vm.replaceUserActivity = function (postData) {
		vm.openModal({
			templateUrl: 'replace_user_activity',
			controller: 'replaceUserActivityCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					user: vm.user
				},
				saveCallback: function () {
					return function(response, modal){
						if(response === 'cancel') {
							KApp.unblock('.changeUserActiveStatus');
						} else {
							vm.changeStatusUser(postData);
						}
						modal.close();
					};
				}
			}
		});
	};

	/**
	 * change user password screen
	 *
	 * @param postData
	 */
	vm.changeUserPassword = function (postData) {
		vm.openModal({
			templateUrl: 'change_user_password',
			controller: 'changeUserPasswordCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					user: vm.user
				},
				saveCallback: function () {
					return function(response, modal){
						if(response === 'cancel') {
							KApp.unblock('.changeUserPassword');
						} else {
						}
						vm.close();
					};
				}
			}
		});
	};

	vm.canDisableUser = function () {
		return vm.user.active;
	};

	/**
	 * check if one can change user status
	 * if status is active -> can disable
	 * if status is disabled -> can enable only if maxUserCount > activeUserCount (e.g. purchased > active)
	 * @return {[type]} [description]
	 */
	vm.canEnableUser = function () {
		if (vm.user.active) return false;
		canEnable = vm.bladeParams.maxUserCount > vm.bladeParams.activeUserCount;
		if (!canEnable) {
			console.log('User limit of ', vm.bladeParams.maxUserCount, ' reached - cannot enable user');
		}
		return canEnable;
	};

	vm.getGroupList = function () {
		var REST = resource.init("group");
		REST.list({
			url: "group/list?contact_id=" + vm.entityId
		}).then(function (data) {
			vm.groups = data.data;
		});
	};
	vm.getGroupList();

	vm.goBack = function () {
		$state.go("app.legal_case");
	};


	vm.edit = function () {
		$state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
	};

	vm.addToGroup = function () {
		$mdDialog.show({
			controller: GroupsListController,
			controllerAs: 'vm',
			templateUrl: 'app/main/groups/groups.modal.html',
			parent: angular.element(document.body),
			clickOutsideToClose: true,
			locals: {
				isModal: true,
				contact_id: vm.entityId
			}
		}).then(function (data) {
			vm.getGroupList();
		});
	};

	vm.deleteFromGroup = function ($index) {
		var REST = resource.init("group");
		REST.delete({
			url: "group/:group_id/people_group/:people_group_id",
			params: {
				group_id: vm.groups[$index].group_id,
				people_group_id: vm.groups[$index].people_group_id
			}
		}).then(function (data) {
			vm.groups.splice($index, 1);
		});
	};

	vm.unlockUser = function () {
		var REST = resource.init("user");
		REST.update({
			id: page_entity_id,
			data: {
				account_is_locked: false
			},
			params: {
				force_update_entity: true
			}
		}).then(function (data) {
			window.location.reload();
		});
	};

	vm.getKpiForDashboard = function(filterInterval = null) {
		var REST = resource.init('user');
		REST.get({
			url: 'user/' + vm.entityId,
			params: {
				action: 'kpi',
				interval: (filterInterval ? filterInterval : 'this_year')
			}
		}).then(function (response) {
			KApp.unblock('#loader_dashboard');
			chartsData = {
				customerChartData: response.customerChartData,
				internalProjectChartData: response.internalProjectsChartData,
				practiceChartData: response.practiceChartData,
				effortDistributionChartData: response.effortDistributionChartData
			};
			vm.userKPI = response;

			// set currency symbol
			vm.widget_currency = vm.userKPI.totals.reporting_currency;
			switch (vm.userKPI.totals.reporting_currency) {
				case "EUR":
					vm.widget_currency = '€';
					break;
				case "USD":
					vm.widget_currency = '$';
					break;
				case "GBP":
					vm.widget_currency = '£';
					break;
				default:
					vm.widget_currency = vm.userKPI.totals.reporting_currency;
			}

			vm.effortDistributionData = response.effort_distribution;
			vm.customerChartError = chartsData.customerChartData.length < 1;
			vm.internalProjectChartError = chartsData.internalProjectChartData.length < 1;
			vm.practiceChartError = chartsData.practiceChartData.length < 1;
			vm.effortDistributionChartError = chartsData.effortDistributionChartData.length < 1;
			$timeout(function () {
				renderCharts(chartsData);
			}).catch(response => {
				console.error(response);
			});
		});
	};

	vm.changeInterval = function(interval) {
		vm.filter_user_data = interval;
		KApp.block('#loader_dashboard');
		vm.getKpiForDashboard(interval);
	};

	vm.chartByCustomer_is_rendered = false;
	vm.chartByCustomer = function(customerChartData, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartByCustomer_is_rendered = true;
					}
				},
			},
			credits: {
				enabled: false
			},
			tooltip: {
				pointFormat: '<b>{point.y}h </b>({point.percent}' + vm.trans('PERCENT_OF_TOTAL') + ')'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: false
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: customerChartData

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if(!redraw) {
			$('#chart1').highcharts(config);
		} else {
			$('#chart1').highcharts(config);
			$('#chart1').highcharts().redraw();
		}
	};

	vm.chartByPractice_is_rendered = false;
	vm.chartByPractice= function(practiceChartData, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartByPractice_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.y}h </b>({point.percent}' + vm.trans('PERCENT_OF_TOTAL') + ')'
			},
			title: {
				text: ""
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: false
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: practiceChartData

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart2').highcharts(config);
		} else {
			$('#chart2').highcharts(config);
			$('#chart2').highcharts().redraw();
		}
	};

	vm.chartByInternalProject_is_rendered = false;
	vm.chartByInternalProject= function(internalProjectChartData, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartByInternalProject_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.y}h </b>({point.percent}' + vm.trans('PERCENT_OF_TOTAL') + ')'
			},
			title: {
				text: ""
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: false
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: internalProjectChartData

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart3').highcharts(config);
		} else {
			$('#chart3').highcharts(config);
			$('#chart3').highcharts().redraw();
		}
	};

	vm.chartEffortDistribution_is_rendered = false;
	vm.chartEffortDistribution = function(effortDistributionChartData, redraw) {
		var config = {
			chart: {
				zoomType: 'xy',
				events: {
					load: function () {
						this.reflow();
						vm.chartEffortDistribution_is_rendered = true;
					}
				}
			},
			credits: {
				enabled: false
			},
			title: {
				text: ''
			},
			subtitle: {
				text: ''
			},
			xAxis: [{
				categories: effortDistributionChartData.effort.categories,
				crosshair: true
			}],
			yAxis: [{ // Primary yAxis
				title: {
					text: ''
				},
				stackLabels: {
					enabled: false,
					style: {
						fontWeight: 'bold',
						color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
					}
				}
			}, { // Secondary yAxis
				title: {
					text: '',
					style: {
						color: Highcharts.getOptions().colors[1]
					}
				},
				labels: {
					style: {
						color: Highcharts.getOptions().colors[1]
					}
				},
				opposite: true
			}],
			tooltip: {
				shared: true
			},
			legend: {
				enabled: true
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: false
					}
				}
			},
			series: effortDistributionChartData.effort.series
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart4').highcharts(config);
		} else {
			$('#chart4').highcharts(config);
			$('#chart4').highcharts().redraw();
		}
	};

	function renderCharts(chartsData) {
		if (chartsData.customerChartData.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartByCustomer_is_rendered) {
				vm.chartByCustomer(chartsData.customerChartData, true);
			} else {
				vm.chartByCustomer(chartsData.customerChartData, false);
				$('#chart1').show();
			}
		}

		if (chartsData.practiceChartData.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartByPractice_is_rendered) {
				vm.chartByPractice(chartsData.practiceChartData, true);
			} else {
				vm.chartByPractice(chartsData.practiceChartData, false);
				$('#chart2').show();
			}
		}

		if (chartsData.internalProjectChartData.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartByInternalProject_is_rendered) {
				vm.chartByInternalProject(chartsData.internalProjectChartData, true);
			} else {
				vm.chartByInternalProject(chartsData.internalProjectChartData, false);
				$('#chart3').show();
			}
		}

		if (chartsData.effortDistributionChartData.effort.series.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartEffortDistribution_is_rendered) {
				vm.chartEffortDistribution(chartsData.effortDistributionChartData, true);
			} else {
				vm.chartEffortDistribution(chartsData.effortDistributionChartData, false);
				$('#chart4').show();
			}
		}
	}

	return vm;

});
