kv.controller('customerEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'customer_id';
    vm.entity = 'customer';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];    
    
    if(page_identifier == 'edit') {
        vm.isNewRecord = false;
        $http.get("/customer/" + page_entity_id).then(function(response) {
            vm.data = response.data;

            // parse industry
            if (vm.data.industries) {
                angular.forEach(vm.data.industries, function (v, i) {
                    vm.data.industries[i].id = v.industry_id;
                    vm.data.industries[i].name = v.industry_name;
                });
            }

            $scope.$broadcast('dataLoaded');
        });
    } else {
        vm.isNewRecord = true;
        vm.data = {};
        vm.data.customer_fiscal_entity = {};
        let defaultObjects = JSON.parse(vm.bladeParams.defaultObjects);
        vm.data = defaultObjects;

        // init create customer with customer fiscal entity type = 2 - PJ
        vm.data.fiscal_entity_type_id = 2;
        vm.data.sys_fiscal_entity_type = {};
        vm.data.sys_fiscal_entity_type.fiscal_entity_type_name = 'PJ';
        vm.data.sys_fiscal_entity_type.fiscal_entity_type_id = 2;
        vm.data.is_active = true;
        vm.data.primary_country_id = 85;
        vm.data.sys_country = {};
        vm.data.sys_country.country_name = 'Romania';
        vm.data.invoice_currency = 'RON';
        vm.data.invoice_language = 'RO';

        if(!vm.data.customer_fiscal_entity){
            vm.data.customer_fiscal_entity = {};
            vm.data.customer_fiscal_entity.customer_fiscal_entity_ext = {};
        }
        vm.data.show_effort_value_on_annex = vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.show_effort_value_on_annex;
        vm.data.show_user_summary_on_annex = vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.show_user_summary_on_annex;
        vm.data.show_due_invoices_on_annex = vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.show_due_invoices_on_annex;

        // clear all the rest
        vm.data.bank = "";
        vm.data.bank_account = "";
        vm.data.vat_type = "";
        vm.data.vat_comment = "";
        vm.data.country_id ="";
        
        console.log("data", vm.data);
    }

    $scope.$watch('vm.data.primary_country_id', function (value) {
        if (value === undefined) {
            return;
        }

        if (value == "85") {
            vm.data.invoice_language = 'RO';
            vm.data.invoice_currency = 'RON';
        } else {
            vm.data.invoice_language = 'EN';
            vm.data.invoice_currency = 'EUR';
        }
    });


    vm.quickAddContact = function quickAddContact() {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function () {
                    return $rootScope.quickAddEntityTemplate = 'contact';
                }
            }
        });
    };

    vm.setPrimary = function ($index, entity) {
        vm.data[entity].forEach((item, index) => {
            item.is_primary = $index == index;
        });
    };

    vm.newEntity = function newEntity(entity_type) {
        if (!vm.data) {
            vm.data = {
                is_primary: true
            };
        }
        
        if (!vm.data[entity_type]) {
            if(entity_type == 'address') {
                return vm.data[entity_type] = [{
                    is_primary: true,
                    address_type_id: 1,
                    type: {
                        address_type_name: 'MAIN' //main address
                    } 
                }];
            } else if(entity_type == 'phone') {
                return vm.data[entity_type] = [{
                    is_primary: true,
                    phone_type_id: 2, //mobile phone
                    type: {
                        phone_type_name: 'MOBILE'
                    }
                }];
            } else if(entity_type == 'email') {
                return vm.data[entity_type] = [{
                    is_primary: true,
                    email_type_id: 2, //business email
                    type: {
                        email_type_name: 'BUSINESS'
                    }
                }];
            } else if(entity_type == 'customer_contact') {
                return vm.data[entity_type] = [{
                    is_primary: true
                }];
            }
        }

        return vm.data[entity_type].push({
            is_primary: true
        });
    };

    vm.deleteEntity = function deleteEntity($index, entity_type, id) {
        if(!id) {
            return vm.data[entity_type].splice($index,1);
        }
        
        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity_type + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity_type].splice($index,1);
            });
        }
    };

    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;

        if (!vm.custom_errors) vm.custom_errors = [];
        // pentru fiecare contact al clientului, verifica daca este completat
        
        // gbarcun dezactivare temporara

        if(page_identifier != 'edit'){
            if (!vm.data.fiscal_entity_type_id) {
                $('[ng-model="vm.data.fiscal_entity_type_id"] .select2-selection--single').css("border", "1px solid red");
                if (!vm.custom_errors) vm.custom_errors = [];
                vm.custom_errors.push(vm.trans('LANG.FISCAL_ENTITY_TYPE') + ' ' + vm.trans('IS_REQUIRED'));
                vm.has_error = true;
            } else {
                $('[ng-model="vm.data.fiscal_entity_type_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
            }if (!vm.data.primary_country_id) {
                $('[ng-model="vm.data.primary_country_id"] .select2-selection--single').css("border", "1px solid red");
                if (!vm.custom_errors) vm.custom_errors = [];
                vm.custom_errors.push(vm.trans('LANG.COUNTRY') + ' ' + vm.trans('IS_REQUIRED'));
                vm.has_error = true;
            } else {
                $('[ng-model="vm.data.primary_country_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
            }
            if (!vm.data.vat_type_id && vm.bladeParams.hasAccessToStdLitigation != 1) {
                $('[ng-model="vm.data.vat_type_id"] .select2-selection--single').css("border", "1px solid red");
                if (!vm.custom_errors) vm.custom_errors = [];
                vm.custom_errors.push(vm.trans('LANG.VAT_TYPE_ERROR'));
                vm.has_error = true;
            } else {
                $('[ng-model="vm.data.vat_type_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
            }
            if (!vm.data.invoice_currency && vm.bladeParams.hasAccessToStdLitigation != 1) {
                $('[ng-model="vm.data.invoice_currency"] .select2-selection--single').css("border", "1px solid red");
                if (!vm.custom_errors) vm.custom_errors = [];
                vm.custom_errors.push(vm.trans('LANG.INVOICE_CURRENCY_ERROR'));
                vm.has_error = true;
            } else {
                $('[ng-model="vm.data.invoice_currency"] .select2-selection--single').css("border", "1px solid #c2cad8");
            }
        }
        if (vm.data.customer_contact) {
            vm.data.customer_contact.forEach(function (item) {
                if (!item.contact_id) {
                    $('[ng-model="customer_contact.contact_id"] .select2-selection--single').css("border", "1px solid red");
                    if (!vm.custom_errors) vm.custom_errors = [];
                    vm.custom_errors.push(vm.trans('LANG.CONTACT') + ' ' + vm.trans('IS_REQUIRED'));
                    vm.has_error = true;
                } else {
                    $('[ng-model="customer_contact.contact_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
                }
            });
        }

        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }
    };
    if (vm.isNewRecord) {
        $scope.$watch('vm.customer_search_cui', function (newValues, oldValues) {
            if (newValues && newValues !== oldValues) {
                vm.data.sys_region = {};
                let data = $('#search_by_cui select').select2('data');
                if (data) {
                    data.forEach(function(company) {
                        if (company.id === vm.customer_search_cui) {
                            var REST = resource.init('sys_region/list');
                            REST.get({
                                params: {
                                    code: "RO-" .concat(company.complete.full_data.adresa_sediu_social.scod_JudetAuto)
                                }
                            }).then(function (data) {
                                var region = data.data[0];
                                vm.data.region_id = region.region_id;
                                vm.data.sys_region.region_name = region.region_name;
                            });

                            if(company.complete.pays_vat == true){
                                vm.data.unique_id = 'RO'.concat(company.id);
                            }else{
                                vm.data.unique_id = company.complete.id;
                            }
                            vm.data.customer_name = company.complete.company_name;
                            vm.data.primary_address = company.complete.address;
                            vm.data.vat_registered = company.complete.pays_vat;
                            if (company.complete.full_data.adresa_sediu_social.scod_JudetAuto == 'B') {
                                vm.data.primary_city = 'Municipiul Bucuresti';
                                vm.data.region_id = 10;
                                vm.data.sys_region.region_name = 'Bucuresti';
                                var unformattedSector = company.complete.full_data.adresa_sediu_social.sdenumire_Localitate.substring(0, 8);
                                vm.data.sector = unformattedSector.replace(/\s/g, '').toUpperCase();
                            } else {
                                vm.data.primary_city = company.complete.full_data.adresa_sediu_social.sdenumire_Localitate;
                            }
                            vm.data.fiscal_id = company.complete.fiscal_id;
                            vm.data.invoice_language = 'RO';
                        }
                    });
                }
            }
        }, true);
    }

    return vm;
});
