kv.controller('CustomerFiscalEntityViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'customer_fiscal_entity_id';
	vm.entity = 'customer_fiscal_entity';
	vm.entityType = 'customer_fiscal_entity';
	vm.entityId = vm.bladeParams.customerFiscalEntityId;
	vm.customerFiscalLastTenOffers = {};
	vm.customerFiscalEntity = JSON.parse(vm.bladeParams.entity);

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	$(document).ready(function () {
		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			$(e.target.hash).find('.chart').each(function () {
				if ($(this).highcharts()) {
					$(this).highcharts().reflow();
				}
			});
		});
	});

	vm.futureActivities = {
		template: 'activities',
		url: API.URL + "activity/activities_with_contact",
		filter: {
			customer_fiscal_entity_id: vm.entityId,
			range: 'future',
		},
		title: $filter('trans')('LANG.FUTURE_ACTIVITIES'),
	};

	vm.getConflictCheck = function () {
		var REST = resource.init('conflict_check');
		REST.get({
			url: "conflict_check/list?customer_fiscal_entity_id=" + vm.entityId
		}).then(function (res) {
			vm.conflicts = res.data;
		});
	};

	vm.getConflictCheck();

	function goBack() {
		$state.go("app.customer_fiscal_entity");
	}

	function editEntity() {
		$state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
	}

	vm.editConflict = function (entity) {
		vm.openModal({
			templateUrl: "customer_fiscal_entity-view-conflict_check-edit",
			controller: 'CustomerFiscalEntityViewConflictCheckEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					if (!entity) {
						entity = {
							customer_fiscal_entity_id: page_entity_id
						};
					}
					return entity;
				},
				saveCallback: function () {
					return function (response) {
						vm.getConflictCheck();
						response.modal.close();
					};
				},
			}
		});
	};

	vm.moveCustomerFiscalEntityOnAnotherCustomer = function () {
		var REST = resource.init('customer_fiscal_entity');
		REST.getArray({
			url: 'customer_fiscal_entity/' + page_entity_id + '/related/linked-entities',
		}).then(function (res) {
			vm.openModal({
				templateUrl: "move_customer_fiscal_entity_on_another_customer",
				controller: 'MoveCustomerFiscalEntityOnAnotherCustomerCtrl',
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					data: function () {
						return {
							customer_fiscal_entity_id: page_entity_id,
							linkedEntities: res
						};
					},
					saveCallback: function () {
						return function (response) {
							response.modal.close();
							location.reload();
						};
					},
				}
			});
		});
	};

	/**
	 * change status / enable or disable entity
	 * @param status
	 */
	vm.toggleStatus = function(status)
	{
		KApp.block('.changeCustomerFiscalEntityStatus');

		try {
			var rest = resource.init('customer');
			if (status !== 'enable' && status !== 'disable') {
				return;
			}

			rest.post({
				url: 'customer_fiscal_entity/' + vm.entityId + '/actions/' + status,
			}).then((response) => {
				if (!response.status) {
					vm.alert('ALERT', vm.trans('LANG.ACTIVE_STATUS_NOT_CHANGE'));
				} else {
					toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
				}
				window.location.reload();
			});
		} catch (e) {
			console.log(e);
		} finally {
			KApp.unblock('.changeCustomerFiscalEntityStatus');
		}
	};


	vm.deleteConflict = function ($index) {
		vm.confirm('Confirm', $filter('trans')('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response == 'cancel') return;

			let rest = this.getInjection('resource').init("conflict_check");
			let ctrl = this;
			rest.delete({
				params: {
					id: vm.conflicts[$index].conflict_check_id
				},
				success: function () {
					vm.conflicts.splice($index, 1);
				}
			});
		});
	}

	vm.getKpiForDashboard = function (filterInterval = null) {
		var REST = resource.init('customer_fiscal_entity');
		REST.getArray({
			url: 'customer_fiscal_entity/' + vm.entityId + '/stats',
			params: {
				interval: (filterInterval ? filterInterval : 'this_year')
			}
		}).then(function (response) {
			KApp.unblock('#loader_dashboard');
			chartsData = {
				chart1data: response[0].userChartData,
				chart2data: response[0].practiceChartData,
				chart3data: response[0].seniorityChartData,
				chart4data: response[1][0],
				chart5data: response[2]
			};
			vm.customerFiscalEntityKPI = response[0];
			vm.activeProjects = response[3];
			vm.chart1Error = chartsData.chart1data.length < 1;
			vm.chart2Error = chartsData.chart2data.length < 1;
			vm.chart3Error = chartsData.chart3data.length < 1;
			vm.chart4Error = chartsData.chart4data.length < 1;
			vm.chart5Error = Object.keys(chartsData.chart5data).length < 1;
			$timeout(function () {
				renderCharts(chartsData);
			}).catch(response => {
				console.error(response);
			});
		});
	};

	vm.changeInterval = function (interval) {
		vm.filter_customer_fiscal_entity_data = interval;
		KApp.block('#loader_dashboard');
		vm.getKpiForDashboard(interval);
	};

	vm.chartUser_is_rendered = false;
	vm.chartUser = function (chart1Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartUser_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart1Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart1').highcharts(config);
		} else {
			$('#chart1').highcharts(config);
			$('#chart1').highcharts().redraw();
		}
	};

	vm.chartPractice_is_rendered = false;
	vm.chartPractice = function (chart2Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartPractice_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart2Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart2').highcharts(config);
		} else {
			$('#chart2').highcharts(config);
			$('#chart2').highcharts().redraw();
		}
	};

	vm.chartSeniority_is_rendered = false;
	vm.chartSeniority = function (chart3Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartSeniority_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart3Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart3').highcharts(config);
		} else {
			$('#chart3').highcharts(config);
			$('#chart3').highcharts().redraw();
		}
	};

	vm.chartCurrentYearOffers_is_rendered = false;
	vm.chartCurrentYearOffers = function (chart4Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartCurrentYearOffers_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart4Data
			}]
		}
		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart4').highcharts(config);
		} else {
			$('#chart4').highcharts(config);
			$('#chart4').highcharts().reflow();
		}
	};

	vm.chartOffersByStatus_is_rendered = false;
	vm.chartOffersByStatus = function (chart5data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'column',
				events: {
					load: function () {
						this.reflow();
						vm.chartOffersByStatus_is_rendered = true;
					}
				},
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			subtitle: {
				text: null
			},
			xAxis: {
				categories: chart5data['categories'],
				tickmarkPlacement: 'on',
				title: {
					enabled: false
				}
			},
			yAxis: {
				title: {
					text: null
				},
				labels: {
					formatter: function () {
						return this.value;
					}
				}
			},
			tooltip: {
				shared: true,
				valueSuffix: ' ' + ($filter('trans')("OFFERS")).toLowerCase()
			},
			plotOptions: {
				column: {
					stacking: 'normal'
				},
				spline: {
					marker: {
						radius: 4,
						lineColor: '#666666',
						lineWidth: 1
					}
				}
			},
			series: [{
				name: $filter('trans')('SUBMITTED'),
				data: chart5data['SUBMITTED'],
				color: '#769efe'
			}, {
				name: $filter('trans')('LOST'),
				data: chart5data['LOST'],
				color: '#fb899e'
			}, {
				name: $filter('trans')('WON'),
				data: chart5data['WON'],
				color: '#bedd8d'
			}, {
				name: $filter('trans')('IN_PREPARATION'),
				data: chart5data['IN_PREPARATION'],
				color: '#a9a0d6',
				// visible: false
			}, {
				name: $filter('trans')('ABANDONED'),
				data: chart5data['ABANDONED'],
				color: '#ffc57c',
				// visible: false
			}, {
				name: $filter('trans')('CONFLICTED'),
				data: chart5data['CONFLICTED'],
				color: '#A47D7C',
				// visible: false
			}, {
				name: $filter('trans')('SUSPENDED'),
				data: chart5data['SUSPENDED'],
				color: '#bbbbbb',
				// visible: false

			}]
		}

		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart5').highcharts(config);
		} else {
			$('#chart5').highcharts(config);
			$('#chart5').highcharts().reflow();
		}
	};

	function renderCharts(chartsData) {
		if (chartsData.chart1data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartUser_is_rendered) {
				vm.chartUser(chartsData.chart1data, true);
			} else {
				vm.chartUser(chartsData.chart1data, false);
				$('#chart1').show();
			}
		}

		if (chartsData.chart2data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartPractice_is_rendered) {
				vm.chartPractice(chartsData.chart2data, true);
			} else {
				vm.chartPractice(chartsData.chart2data, false);
				$('#chart2').show();
			}
		}

		if (chartsData.chart3data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartSeniority_is_rendered) {
				vm.chartSeniority(chartsData.chart3data, true);
			} else {
				vm.chartSeniority(chartsData.chart3data, false);
				$('#chart3').show();
			}
		}

		if (chartsData.chart4data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartCurrentYearOffers_is_rendered) {
				vm.chartCurrentYearOffers(chartsData.chart4data, true);
			} else {
				vm.chartCurrentYearOffers(chartsData.chart4data, false);
				$('#chart4').show();
			}
		}

		if (Object.keys(chartsData.chart5data).length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartOffersByStatus_is_rendered) {
				vm.chartOffersByStatus(chartsData.chart5data, true);
			} else {
				vm.chartOffersByStatus(chartsData.chart5data, false);
				$('#chart5').show();
			}
		}
	}

	vm.getCustomerFiscalLastTenOffers = function () {
		setTimeout(function () {
			var REST = resource.init('customer_fiscal_entity');
			REST.getArray({
				url: 'customer_fiscal_entity/' + vm.entityId + '/related/last_10_offers'
			}).then(function (data) {
				vm.customerFiscalLastTenOffers = data;
			})
		}, 100);
	}

	vm.updateCollectionContacts = function () {
		vm.confirm(vm.trans('LANG.WARNING') + ' ' + vm.trans('LANG.UPDATE_COLLECTION_CONTACTS'), vm.trans('LANG.UPDATE_COLLECTION_CONTACTS_CUSTOMER')).then(response => {
			if (response !== "ok") return;
			KApp.block('.modal-content');
			let rest = vm.getInjection('resource').init('customer_fiscal_entity');
			rest.post({
				url: 'customer_fiscal_entity/' + vm.entityId + '/actions/update_collection_contact',
				params: {
					action: 'update_collection_contact'
				}
			}).then(function (data) {
				KApp.unblock('.modal-content');
				if(data.error && data.error.error_code === 1){
					toastr.error(data.error.message);
				}else{
					toastr.success(data.message);
				}
			});
		});
	}

	vm.addEditEfacturaFields = function (customer_fiscal_entity_id, type){
		vm.openModal({
			templateUrl: "efactura-fields-configuration",
			controller: 'efacturaFieldsConfigurationCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: {
					entity_id: customer_fiscal_entity_id,
					type: type
				}
			}
		}).then(response => {
			// window.location = "/customer_fiscal_entity/" + customer_fiscal_entity_id;
		});
	};

	vm.getDashboardData = function (){
		//vm.getCustomerFiscalActivities();
		vm.getCustomerFiscalLastTenOffers();
		vm.getKpiForDashboard();
	};

	return vm;
});

