kv.controller('projectReferenceEditCtrl', function ($uibModalInstance, params, bladeParams, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'project', 'project_id', $scope, params);

	if (params.index == -1) {
		vm.isNewRecord = true;
		vm.newReference = JSON.parse(bladeParams.defaultobjectreference);
		// needed a second json parse cause data was still string
		vm.data = vm.newReference;

		// setup responsible from project
		vm.project = JSON.parse(bladeParams.entity);
		vm.data.responsible_id = vm.project.responsible_id;
		vm.data.responsible = vm.project.responsible;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		vm.rest.get({id: params.entity.project_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved)
			{
				vm.data = response;
				if(vm.data.project_manager) {
					angular.forEach(vm.data.project_manager, function(v,k) {
						vm.data.project_manager[k] = {id: v.user.user_id, name: v.user.full_name}
					});
				}
				if(vm.data.project_external_user) {
					angular.forEach(vm.data.project_external_user, function(v,k) {
						vm.data.project_external_user[k] = {id: v.user.user_id, name: v.user.full_name}
					});
				}
				if(!vm.data.project_budget) {
					vm.data.project_budget = {};
					if(!vm.data.project_budget.enable_budget_alert) {
						vm.data.project_budget.enable_budget_alert = false;
						vm.data.project_budget.alert_treshold_percent = "90.00";
					}
				}
			}
			else {
				console.error(response);
			}
		});
	}

	vm.resetAlert = function (entity, id) {
		if(!id){
			vm.data.project_budget.alert_created_at = null;
		}
	};

	$scope.$watch("vm.data.enabled", function (value) {
		vm.diseable_reference_errors = [];
		if (value === 0) {
			let rest = vm.getInjection('resource').init('project');
			if (typeof vm.data.project_id !== 'undefined'){
				rest.get({
					url: 'project/' + vm.data.project_id + '/checkIfReferenceCanBeClosed',
					params: {
						check_reference_for_disable: true
					}
				}).then((response) => {
					if (response.diseable_reference === false){
						if (!vm.diseable_reference_errors) vm.diseable_reference_errors = [];
						vm.diseable_reference_errors.push(vm.trans('LANG.UNINVOICED_REFERENCE_FLAT_FEE_OR_SUCCESS_FEE'));
					}
				});
			}
		}
	});

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;

		// provizoriu -- data should be read from backend through api call
		if(vm.diseable_reference_errors.length !== 0){
			angular.forEach(vm.diseable_reference_errors, function (v, i) {
				if (!vm.custom_errors) vm.custom_errors = [];
				vm.custom_errors.push(v);
				vm.has_error = true;
			});
		}

		if(vm.has_error){
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}

	};

	vm.prepareDataToSave = function () {
		let project = JSON.parse(bladeParams.entity);
		let dataToSend = angular.copy(vm.data);
		dataToSend.parent_id = project.project_id;
		dataToSend.customer_id = project.customer_id;
		if(dataToSend.project_budget){
			dataToSend.project_budget.alert_treshold_value = dataToSend.project_budget.alert_treshold_percent/100 * dataToSend.project_budget.budget_value;
		}
		dataToSend.customer_fiscal_entity = project.customer_fiscal_entity_id;
		dataToSend.tenant_fiscal_entity_id = project.tenant_fiscal_entity_id;
		delete dataToSend.project_team;
		return dataToSend;
	};

	return vm;
});
