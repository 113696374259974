kv.component('quickAddEntity', {
	templateUrl: function ($rootScope) {
		return 'quick-add-' + $rootScope.quickAddEntityTemplate;
	},
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	size: 'sm',
	controller: function ($scope, $element, $attrs, $injector, $filter, $http, resource, validator, $rootScope) {
		var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter, 'quick-add-entity-params');
		vm.isNewRecord = true;

		vm.$onInit = function () {
			let extraParams = {};
			if (vm.resolve.entity === 'supplier') {
				vm.entity = 'customer_fiscal_entity';
				extraParams = {customer_type_id: 4};
			} else {
				vm.entity = vm.resolve.entity;
			}
			vm.rest = resource.init(vm.entity);
			vm.data = {};
			var defaultObject = {};

			// get default values from server
			try {
				vm.rest.get({
					url: 'default-objects/' + vm.entity,
					params: extraParams
				}).then(function (data) {
					defaultObject = data;
					// apply any potential local defaults, sent as param to the component
					vm.data = vm.applyLocalDefaults(defaultObject);
					vm.data.quick = true;
				});
			} catch (e) {
				console.log('Error getting default object for ' + vm.entity + ': ' + e);
				// apply any potential local defaults, sent as param to the component
				vm.data = vm.applyLocalDefaults({});
			}

		// 		todo: delete commented code
		// 	switch ($rootScope.quickAddEntityTemplate) {
		// 		case 'customer':
		// 			// if not ADMIN, MANAGEMENT, BILLING or SUPPORT, fill customer_type by default with LEAD
		// 			if (vm.bladeParams.hasRights == 0 || !vm.bladeParams.hasRights) {
		// 				vm.data.customer_type_id = 2;
		// 				vm.data.customer_type = {
		// 					customer_type_id: 2,
		// 					customer_type_name: $filter('trans')('LANG.LEAD'),
		// 				};
		// 			}
		// 			vm.data.sys_fiscal_entity_type = {
		// 				fiscal_entity_type_id : 2,
		// 				fiscal_entity_type_name : $filter('trans')('LANG.PJ')
		// 			};
		// 			vm.data.fiscal_entity_type_id =2;
		// 			vm.data.customer_ext = {};
		// 			vm.data.is_active = 1;
		// 			vm.data.customer_ext.industry_id = null;
		// 			break;
		// 		case 'customer_fiscal_entity':
		// 			if (vm.resolve.customer) {
		// 				vm.data.customer_id = vm.resolve.customer.customer_id;
		// 				vm.data.customer = vm.resolve.customer;
		// 			}
		// 			vm.data.fiscal_entity_type_id = 2;
		// 			vm.data.sys_fiscal_entity_type = {
		// 				fiscal_entity_type_id: 2,
		// 				fiscal_entity_type_name: $filter('trans')('LANG.PJ')
		// 			};
		// 			if (vm.resolve.customer_type) {
		// 				vm.data.customer_type_id = vm.resolve.customer_type.customer_type_id;
		// 				vm.data.sys_customer_type = vm.resolve.customer_type;
		// 			}
		// 			vm.data.customer_fiscal_entity_ext = {};
		//
		// 	}
		};

		/**
		 * Apply local defaults to the data object
		 * these changes are sent as a default object when calling the component
		 * var modalInstanceContact = $uibModal.open({
		 *            animation: true,
		 *            component: 'quickAddEntity',
		 *            resolve: {
		 *                entity: function() {
		 *                    return $rootScope.quickAddEntityTemplate = 'customer_fiscal_entity';
		 *                },
		 *                defaults: { -- insert defaults here -- }
		 *            }
		 *        }).result.then(function() {
		 *            window.location.reload();
		 *        });
		 */
		vm.applyLocalDefaults = function (initialValues)
		{
			if (vm.resolve && vm.resolve.defaults) {
				const originalObject = angular.copy(initialValues);
				return Object.assign({}, originalObject, vm.resolve.defaults);
			}

			return initialValues;
		};

		/**
		 * prepare data before saving
		 *
		 * @returns {*}
		 */
		vm.prepareDataToSave = function () {
			let data = angular.copy(vm.data);
			switch (vm.resolve.entity) {
				case 'contact':
					data.contact_ext = {};
					data.primary_company = data.customer_contact ? data.customer_contact[0].customer.customer_name : null;
					if(data.phone){
						data.phone[0].is_primary = true;
						data.phone[0].phone_type_id = 2;
					}
					if(data.contact_user){
						data.contact_user[0].is_primary = true;
						data.contact_user[0].user.user_id = data.contact_user[0].user_id;
					}
					if(data.email) {
						data.email[0].is_primary = true;
						data.email[0].email_type_id = 2;
					}
					data.contact_ext.birthday = null;
					break;
				case 'supplier':
					data.customer_type_id = 4; // Supplier
					data.supplier = true;
					break;
				case 'customer_fiscal_entity':
					// data.customer_fiscal_entity_ext = {};
					data.customer_fiscal_entity_ext.use_electronic_billing = 0;
					data.customer_fiscal_entity_ext.invoice_currency = API.LEGAL_CURRENCY ? API.LEGAL_CURRENCY : API.REPORTING_CURRENCY;
					data.primary_country_id = 85;
					data.sys_country = {
						country_id : 85,
						country_name : 'Romania'
					};

					break;
				default:
			}
			return data;
		};

		vm.saveCallback = function (response) {
			if(vm.resolve.refresh_after) {
				window.location.reload();
			}
			vm.dismiss({$value: response.data});
		};
		vm.cancel = function () {
			vm.dismiss({
				$value: 'cancel'
			});
		};
		return vm;
	},
	controllerAs: 'vm'
});
