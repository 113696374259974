kv.controller('ClientGenerationReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var params = kvUtils.getAllQueryParams();
    let graphData = {};

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        // load graphs
        if ($element.find('#page-data') && $element.find('#page-data').attr('graph-data')) {
            graphData = JSON.parse($element.find('#page-data').attr('graph-data'));
            console.log(graphData);
        }

        $('#table').DataTable({
            fixedHeader: {
                header: false,
                footer: true
            },
            "bPaginate": false,

            "paging":   false,
            "ordering": false,
            "info":     false,
            "bLengthChange": false
        });

        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            addClass('no-print').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');

        if (graphData) {
            renderChart('#gauge-by-source', graphData.bySource, 'By Source');
            renderChart('#gauge-by-user', graphData.byUser, 'By Partner');
        }

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            $(e.target.hash).find('.chart').each(function() {
                if($(this).highcharts()) {
                    $(this).highcharts().reflow();
                }
            });
        });
    });

    vm.isLoading = true;

    function renderChart(chartName, chartData, chartTitle) {
        $(chartName).hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        zIndex: 9999
                    }
                }
            },
            title: {
                text: chartTitle
            },
            tooltip: {
                pointFormat: '<b>{point.name}: {point.y}</b><br/>{point.percentage:.1f}% or total'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                    },
                    showInLegend: false,
                    innerSize: '60%'
                }
            },
            series: [{
                colorByPoint: true,
                data: chartData
            }]
        };

        $(chartName).highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $(chartName).show();
            $(chartName).highcharts().reflow();
        }, 1);
    }

	return vm;
});
