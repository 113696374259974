kv.controller('invoiceListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'invoice_id';
	vm.entity = 'invoice';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.today = moment().format('YYYY-MM-DD');

	vm.getInfinitScroll({
		params: {
			action: 'invoice'
		},
		search: {
			scope: 'last_year',
		},
		url: "invoice",
	});

	vm.delete = function deleteEntity($index) {
		vm.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		}).then(function () {
			var REST = resource.init("invoice");

			REST.delete({
				params: {
					id: vm.infinitScroll.items[$index][vm.primaryKey]
				}
			}).then(function (response) {
				if (response.status) {
					vm.infinitScroll.items.splice($index, 1);
					toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
				} else {
					toastr.error(response.error.status_id);
				}
			});
		});
	};

	vm.deleteFromJournal = function($index) {
		var REST = resource.init('sun_export_detail');
		REST.delete({
			url: 'sun_export_detail/invoice/' + vm.infinitScroll.items[$index][vm.primaryKey],
		});
	}

	vm.validateSomeInputs = function () {
		if (vm.infinitScroll.items.length > 100) {
			toastr.error(vm.trans('LANG.EXCEED_NUMBER_OF_INVOICES'));
		}else{
			window.open(vm.bladeParams.invoiceMergeExportAction + "?format=pdf&save=true&color=blue&with_currency=true&" + $filter("objToParams")(vm.infinitScroll.search), '_blank');
		}
	};

	vm.bulkDownloadPDF = function () {
		window.open(vm.bladeParams.invoiceMergeExportAction + "?format=pdf&save=true&color=blue&with_currency=true&" + $filter("objToParams")(vm.infinitScroll.search), '_blank');
	};

	vm.validateSomeInputsAnnex = function () {
		window.open(vm.bladeParams.invoiceMergeExportAnnexAction + "?format=pdf&save=true&color=blue&with_currency=true&" + $filter("objToParams")(vm.infinitScroll.search), '_blank');
	};

	vm.validateSomeInputsAnaf = function (items) {
		var invoice_validation_error = 0;
		var invoice_type_error = 0;
		var efactura_already_sent = 0;
		var efactura_token_is_not_authorized = 0;
		angular.forEach(items, function (v, i) {
			var token_expiration_date = new Date(items[i].invoice_ext.tenant_fiscal_entity.efactura_token_expiration_date);
			var makeDate = new Date();
			if(items[i].invoice_status_id !== 2){
				invoice_validation_error = 1;
			}
			if(items[i].invoice_type_id !== 2){
				invoice_type_error = 1;
			}
			if(items[i].efactura!= null && (items[i].efactura.status === 'processed' || items[i].efactura.status === 'sending')){
				efactura_already_sent = 1;
			}
			if(!items[i].invoice_ext.tenant_fiscal_entity.is_token_valid) {
				efactura_token_is_not_authorized = 1;
			}
		});

		if(invoice_validation_error === 1){
			toastr.error(vm.trans('LANG.INVOICES_VALIDATION_ERROR'));
		}else if(invoice_type_error === 1){
			toastr.error(vm.trans('LANG.INVOICES_TYPE_ERROR'));
		}else if(efactura_already_sent === 1){
			toastr.error(vm.trans('LANG.INVOICES_EFACTURA_ALREADY_SENT_ERROR'));
		}else if(efactura_token_is_not_authorized === 1){
			toastr.error(vm.trans('LANG.ANAF_TOKEN_ERROR'));
		}else{
			vm.confirm(vm.trans('INVOICE_COUNT') + ' : ' + items.length, vm.trans('CONFIRM_SEND_TO_ANAF')).then(response => {
				if (response !== 'ok') return;
				vm.entity = 'invoice';
				var REST = resource.init(vm.entity);
				KApp.block('.invoice-list');
				REST.getArray({
					url: vm.bladeParams.invoiceSendToAnafAction + "?scope=anaf&" + $filter("objToParams")(vm.infinitScroll.search)
				}).then(function (response) {
					KApp.unblock('.invoice-list');

					// TODO: aici de fapt ar trebui sa primeasca un status true, sau daca este fals erori asociate
					// array ar trebui sa fie ceva gen:
					// array(
					// 	'status' => true,
					// 	'success_count' => 10
					// 	'error_count' => 5
					// 	'errors' => array([id: 100; message: message])

					// mesaj: if succes > 0 and error = 0 -> succes
					// mesaj: if succes > 0 and error > 0 -> succes partial (am trimis doar 5 facturi din 10. verificati mesajele pentru detalii)
					// mesaj: if succes = 0 and error > 0 -> error


					if(response.error && response.error.error_code){
						toastr.error((response.error.message + ". </br> " + response.error.details));
					} else {
						toastr.success(vm.trans('LANG.INVOICES_SENT_TO_ANAF'));
						location.reload();
					}
				});
			});
			//window.open(vm.bladeParams.invoiceSendToAnafAction + "?" + $filter("objToParams")(vm.infinitScroll.search), '_blank');
		}
	};

	vm.streamDownload = function (action) {
		window.location.href = 'invoice/bulk/' + action + '?limit=none&' + $filter('objToParams')(vm.infinitScroll.search);
	};

	/**
	 * bulk download invoices
	 *
	 * @param format
	 */
	vm.bulkDownload = function (action) {
		if (vm.infinitScroll.total > 500) {
			toastr.error('Maximum 500 invoices can be downloaded at once.');
			return;
		}
		var REST = resource.init('invoice');

		// estimated download time, roughtly 5 invoices per second
		let estimatedDownloadTime = vm.infinitScroll.total * 1500;
		// double estimation for merge with annex
		if (action === 'downloadPDFWithAnnex' || action === 'downloadZIPWithAnnex' || action === 'downloadPDFZipWithAnnex') {
			estimatedDownloadTime = estimatedDownloadTime * 2;
		}

		let estimatedDownloadTimeSeconds = 3000;

		 	// show popup with loading
		swal({
			// position: 'top-right',
			type: 'info',
			title: 'Downloading '+ vm.infinitScroll.total +' invoices. ' +
				'You will get an email with the download link. ',
			showConfirmButton: true,
			showCancelButton: true,
			buttons: true,
			allowOutsideClick: false
		}).then((result) => {
			if (result.value) {
				REST.get({
					url: 'invoice/bulk/' + action + '?limit=none&' + $filter('objToParams')(vm.infinitScroll.search)
				})
					.then(function (response) {
						swal.close();
						if (response.status === 'success') {
							if (response.file && response.file.length > 0 && response.file !== 'undefined') {
								window.location = window.location.origin + '/invoice/bulk/' + action + '?file_name=' + response.file;
							}
						} else {
							swal({
								position: 'top-right',
								type: 'error',
								title: response.errors,
								showConfirmButton: true,
							});
						}
					})
					.catch(function exception(ex) {
						swal.close();
						KApp.unblock('.k-content');
						toastr.error(ex.error.message);
					});
			} else {
				swal.close();
			}
		});


	 };

	return vm;
});

