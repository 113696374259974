kv.controller('changeUserPasswordCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'user', 'user_id', $scope, params);

	vm.user = params.user ? params.user : {};
	vm.saveCallback = saveCallback;

	vm.data = {
		password: '',
		password_confirm: '',
		force_reset_password: false,
	};

	vm.errors = [];

	/**
	 * save password change
	 */
	vm.save = function () {
		let rest = vm.getInjection('resource').init('user');
		vm.errors = [];
		rest.post({
			url: 'user/' + vm.user.user_id + '/actions/change-password',
			data: {
				password: vm.data.password,
				password_confirm: vm.data.password_confirm,
				force_reset_password: vm.data.force_reset_password,
				do_not_check_old_password: true,
			}
		}).then(function (response) {
			// 200, but with errors
			if (response.status === false && response.error) {
				// Iterate through the error object and get the first error message
				for (const field in response.error) {
					if (response.error[field] && response.error[field].length > 0) {
						vm.errors.push(response.error[field][0]);
					}
				}
			}

			// 200, success
			if (response.status === true) {
				toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'), {timeOut: 2000});
				vm.cancel();
			}
		})
		// Catch any HTTP errors (like 500) and handle them
		.catch(function (error) {
			// Push a general error message to vm.errors array
			vm.errors.push(vm.trans('LANG.ERROR_WHILE_SAVING'));
		})
		.finally(function () {
			// Always call processErrors() to display the error messages
			vm.processErrors();
			window.location.reload();
		});
	};

	vm.processErrors = function () {
		if(vm.errors.length > 0) {
			$('.error_messages').css('display', 'block');
		} else {
			$('.error_messages').css('display', 'none');
		}
	};

	vm.cancel = function () {
		vm.modal.close();
	};

	return vm;
});
