class DashboardLawyerAgendaCtrl extends baseCtrl {
	constructor($http, $element, cmdate, API, resource, $timeout, $injector, $scope, $attrs) {
		super($scope, $element, $attrs, $injector);
		this.data = JSON.parse($element.find('#page-data').attr('page-data'));
		// this.$calendarAddEvent = $calendarAddEvent;
		this.API = API;
		this.http = $http;
		this.cmdate = cmdate;
		this.resource = resource;
		this.timeout = $timeout;
	}
	
	/**
	 * toggle task status
	 * @param entity
	 * @param event
	 */
	changeTaskStatus(entity, event){
		var selected = 'task-' + entity.entity_id;
		const vat_value = document.getElementById(selected);
		if (event.target.tagName !== 'INPUT') return;
		entity.done = !entity.status;
		if(entity.done === false){
			vat_value.style.textDecoration = 'line-through';
		}else{
			vat_value.style.textDecoration = 'none';
		}
		var REST = this.resource.init("task");
		REST.update({
			id: entity.entity_id,
			params: {
				action: (entity.done ? 'markToDo' : 'markCompleted')
			}
		});

	}

	addEditTask (entity, index) {
		this.openModal({
			templateUrl: "task-edit",
			size: 'lg',
			controller: "taskEditCtrl",
			controllerAs: "vm",
			backdrop: 'static',
			keyboard: false,
			resolve: {
				params: function () {
					return {
						task_id: entity.entity_id,
					};
					// if (!entity) {
					// 	entity = {
					// 		task_id: entity.task_id
					// 	};
					// }
					// return entity;
				}
			}
		}).then(response => {
			//vm.infinitScroll.search.freshData = new Date().getTime();
		});
	}

	addTask() {
		let start_date = moment().format("YYYY-MM-DD HH:mm:ss");
		// this.$calendarAddEvent(this.saveCallback.bind(this), start, end, 'add', false, false, false, null);
		this.openModal({
			templateUrl: "task-edit",
			size: 'lg',
			controller: "taskEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: function () {
					let entity = {
						start_date: start_date,
					};
					return entity;
				}
			}
		}).then(response => this.saveCallback(response));
	}

	/*
	  * send data to backend to save task
		*/
	saveCallback1111(data) {
		data.start_date = this.cmdate.toServer(data.start_date);
		data.editable = true;
		if (data.all_day) {
			data.start_date = data.start_date.substring(0, 10) + " " + this.API.START_DAY_AT;
		}
		var REST = this.resource.init('calendar');
		/**
		 * callback after data was saved
		 * @param response
		 */
		var createCallback = function (response) {
			response.entity_type = 'task';
			response.entity_id = response.task_id;
			response.title = response.task_name;
		//	response.end_day = moment(response.end_date).format('DD MMM');
			response.status = false;
			//If entity has end = today
			if (moment().format('DD.MM.YYYY') == moment(response.end_date).format('DD.MM.YYYY'))
				this.data.today.push(response);
			// if entity end> today
			else if (moment().toDate() < moment(response.end_date).toDate()) this.data.tomorrow.push(response);
			// if entity end < today
			else this.data.pastDue.push(response);
		};
		REST.create({
			url: "calendar",
			data: data
		}).then(createCallback.bind(this));
	}

	saveCallback(response) {
		response.entity.entity_type = 'task';
		response.entity.entity_id = response.entity.task_id;
		response.entity.title = response.entity.task_name;
		response.entity.end_day = moment(response.entity.end_date).format('DD MMM');
		response.entity.status = false;
		//If entity has end = today
		if (moment().format('DD.MM.YYYY') == moment(response.entity.end_date).format('DD.MM.YYYY'))
			this.data.today.push(response.entity);
		// if entity end> today
		else if (moment().toDate() < moment(response.entity.end_date).toDate()) this.data.tomorrow.push(response.data);
		// if entity end < today
		else this.data.pastDue.push(response.entity);
	}
}

angular.module("kv").controller("DashboardLawyerAgendaCtrl", DashboardLawyerAgendaCtrl);
