kv.controller('GeneralStatisticsReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

    let graphData = {};
    if ($element.find('#page-data') && $element.find('#page-data').attr('graph-data')) {
        graphData = JSON.parse($element.find('#page-data').attr('graph-data'));
    }
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    vm.isLoading = true;


    /**
     * show a popup with drill down by customer
     * @param type
     * @param event
     */
    vm.showDetailsPopup = function (type, event) {
        var element = event.currentTarget;
        var parsedChartData = {};
        switch(type) {
            case 'initiator-customers': parsedChartData = graphData.initiated_customers.data; break;
            case 'managed-customers': parsedChartData = graphData.managed_customers.data; break;
        }
        vm.openModal({
            templateUrl: type + "-stats",
            controller: 'initiatorCustomersStatsCtrl',
            controllerAs: 'vm',
            size: 'xl',
            resolve: {
                params: {
                    entity: parsedChartData
                }
            }
        });
    };

    /**
     * render a simple chart in a container
     * @param container
     * @param type
     * @param chartData
     */
    function renderInlineDonutChart(container, chartData, type = 'donut') {
        // $(container).hide();
        // console.log(chartData);

        var chartConfig = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                // height: 160,
                // width: 160,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} % ({point.percentage:.1f} %)</b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: false,
                    innerSize: '60%'
                }
            },
            // series: chartData,
            series: [{
                data: chartData
            }]
        };

        // Initialize Highcharts and store the chart instance
        var chart = $(container).highcharts(chartConfig);

        setTimeout(function () {
            // $(container).show();
            $(container).highcharts().reflow();
        }, 1);
    }

    /**
     * prepare data for chart, transforming from text to float
     *
     * @param data
     * @returns {*[]}
     */
    function prepareChartData(data) {
        var chartData = [];
        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            chartData.push({
                name: item.name,
                y: parseFloat(item.value ? item.value : item.y) // Ensure value or y is parsed as float
            });
        }
        return chartData;
    }

    // render charts
    $(document).ready(function() {
        if (graphData === undefined || graphData === null) {
            return;
        }

        if (graphData.due_amounts.data !== undefined && graphData.due_amounts.data !== null) {
            renderInlineDonutChart('#due_amounts_chart', prepareChartData(graphData.due_amounts.data));
            $('#due_amounts_chart').highcharts().reflow();
        }

        if (graphData.timesheet_types.data !== undefined && graphData.timesheet_types.data !== null) {
            renderInlineDonutChart('#timesheet_types_chart', prepareChartData(graphData.timesheet_types.data));
            $('#timesheet_types_chart').highcharts().reflow();
        }
    });

    return vm;
});
