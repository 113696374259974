kv.controller('CustomerFiscalEntityEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'customer_fiscal_entity_id';
	vm.entity = 'customer_fiscal_entity';
	vm.rest = vm.getInjection('resource').init(vm.entity);

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	if (page_identifier == 'edit') {
		vm.isNewRecord = false;
		$http.get("/customer_fiscal_entity/" + page_entity_id).then(function (response) {
			vm.data = response.data;
			if (vm.data.customer_fiscal_entity_affiliate) {
				angular.forEach(vm.data.customer_fiscal_entity_affiliate, function (v, i) {
					vm.data.customer_fiscal_entity_affiliate[i].id = v.affiliate_id;
					vm.data.customer_fiscal_entity_affiliate[i].name = v.name;
				});
			}
			$scope.$broadcast('dataLoaded');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		let defaultObjects = JSON.parse(vm.bladeParams.defaultObjects);
		vm.data = defaultObjects;
		if (vm.bladeParams.customer) {
			let customer = JSON.parse(vm.bladeParams.customer);
			if (customer) {
				vm.data.customer_id = customer.customer_id;
				vm.data.customer = {
					customer_name: customer.customer_name
				};
				vm.data.customer_type_id = customer.customer_type_id;
				vm.data.sys_customer_type = {
					customer_type_name: customer.customer_type.customer_type_name
				};
			}
		}

		vm.data.primary_country_id = 85;
		vm.data.sys_country = {};
		vm.data.sys_country.country_name = 'Romania';
		vm.data.customer_fiscal_entity_ext.invoice_currency = API.LEGAL_CURRENCY ? API.LEGAL_CURRENCY : API.REPORTING_CURRENCY;
	}

	vm.quickAddCustomer = function quickAddCustomer() {
		var modalInstanceContact = $uibModal.open({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer';
				}
			}
		});
	};

	vm.quickAddContact = function quickAddContact() {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'contact';
				}
			}
		}).then(function (response) {
			vm.data.main_contact_id = response.contact_id;
			vm.data.main_contact = {
				contact_name: response.contact_name
			};
		});
	};


	vm.newEntity = function newEntity(entity_type) {
		kvUtils.newRelatedModelEntityFor(entity_type, vm.data);
	};

	vm.deleteEntity = function deleteEntity($index, entity_type, id) {
		if (!id) {
			return vm.data[entity_type].splice($index, 1);
		}

		if (page_entity_id) {
			vm.delete({
				url: vm.entity + '/:id/' + entity_type + '/:entityId',
				params: {
					id: page_entity_id,
					entityId: id
				}
			}).then(function () {
				vm.data[entity_type].splice($index, 1);
			});
		}
	};

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;
		if(!vm.data.customer_fiscal_entity_ext.invoice_currency){
			$('[ng-model="vm.data.customer_fiscal_entity_ext.invoice_currency"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.INVOICE_CURRENCY_ERROR'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.customer_fiscal_entity_ext.invoice_currency"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		if(vm.API.user.tenant.short_name === 'nndkp' && !vm.data.account){
			$('[ng-model="vm.data.account"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.ACCOUNT_ERROR'));
			vm.has_error = true;
		}else{
			$('[ng-model="vm.data.account"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		if(!vm.data.customer_fiscal_entity_ext.vat_type_id){
			$('[ng-model="vm.data.customer_fiscal_entity_ext.vat_type_id"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.VAT_TYPE_ERROR'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.customer_fiscal_entity_ext.vat_type_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}


		if(vm.has_error){
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}

	};

	if (vm.isNewRecord) {
		$scope.$watch('vm.customer_search_cui', function (newValues, oldValues) {
			if (newValues && newValues !== oldValues) {
				vm.data.sys_region = {};
				let data = $('#search_by_cui select').select2('data');
				if (data) {
					data.forEach(function(company) {
						if (company.id === vm.customer_search_cui) {
							var REST = resource.init('sys_region/list');
							REST.get({
								params: {
									code: "RO-" .concat(company.complete.full_data.adresa_sediu_social.scod_JudetAuto)
								}
							}).then(function (data) {
								var region = data.data[0];
								vm.data.region_id = region.region_id;
								vm.data.sys_region.region_name = region.region_name;
							});

							if(company.complete.pays_vat == true){
								vm.data.unique_id = 'RO'.concat(company.id);
							}else{
								vm.data.unique_id = company.complete.id;
							}
							if (company.complete.full_data.adresa_sediu_social.scod_JudetAuto == 'B') {
								vm.data.primary_city = 'Municipiul Bucuresti';
								vm.data.region_id = 10;
								vm.data.sys_region.region_name = 'Bucuresti';
								var unformattedSector = company.complete.full_data.adresa_sediu_social.sdenumire_Localitate.substring(0, 8);
								vm.data.sector = unformattedSector.replace(/\s/g, '').toUpperCase();
							} else {
								vm.data.primary_city = company.complete.full_data.adresa_sediu_social.sdenumire_Localitate;
							}
							vm.data.customer_fiscal_entity_ext.unique_id = vm.data.unique_id.toString();
							vm.data.name = company.complete.company_name;
							if(company.complete.pays_vat === true){
								vm.data.customer_fiscal_entity_ext.vat_registered = 1;
							}else{
								vm.data.customer_fiscal_entity_ext.vat_registered = 0;
							}
							vm.data.primary_address = company.complete.address;
							vm.data.customer_fiscal_entity_ext.fiscal_id = company.complete.fiscal_id;
						}
					});
				}
			}
		}, true);
	}


	return vm;
});
