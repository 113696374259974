class baseCtrl {
	constructor($scope, $element, $attrs, $injector, $filter = null, bladeParamsId = null) {
		if (!$injector || !$injector.invoke || !$injector.instantiate) console.error("super method must have '$scope, $element, $attrs, $injector' as parameters");
		this.bladeParamsId = bladeParamsId;
		this.injections = {};
		this.injector = $injector;
		this.scope = $scope;
		this.element = $element;
		this.attrs = $attrs;
		if (!$filter) this.filter = this.getInjection('$filter');
		else this.filter = $filter;
		this.trans = this.filter('trans');
		this.API = this.getInjection('API');
		this.rootScope = this.getInjection('$rootScope');
		this.searchInit = {};
		this.injectSideFilters();
		this.bladeParams = this.getBladeParams();
		this.validator = this.getInjection('validator');
		this.isNewRecord = false;
		this.firstDataLoaded = false;
		this.custom = {};
		this.total_files_count = 0;
		$scope.$on("update_files_total_count", (event, data) => {
			this.total_files_count = data;
		});
		// let $timeout = this.getInjection("$timeout");
		$scope.$broadcast("dataHasLoaded");
		this.super = this;
		this.dataHasLoaded = false;

		Object.defineProperty(this, "super", {
			get: () => {
				return Object.getPrototypeOf(this);
			}
		});
		if ($element) {
			$element.find('input').attr('autocomplete', Math.random());
		}

		// check push notifications - only on HomePage
		// if ( this.FCM === undefined || this.FCM === null) {
		// 	if (window.location.href.includes('/home')) {
		// 		this.FCM = this.getInjection('FCM');
		// 		this.checkWebPush();
		// 	}
		// }
	}

	getBladeParams() {
		if (!this.bladeParamsId && !this.element) return {};
		let element = this.bladeParamsId ? angular.element("#" + this.bladeParamsId) : this.element.find("blade-params");
		if (element.length == 0) return {};
		let params = {};
		for (let i = 0; i < element[0].attributes.length; i++) {
			let attr = element[0].attributes[i];
			let property = attr.nodeName.camelCase();
			if (["style", "id"].includes(property)) continue;
			params[property] = attr.nodeValue;
		}
		// console.debug("blade-params", params);
		return params;
	}

//inject referinta la sideFiltersCtrl si commentCtrl
	injectSideFilters() {
		if (!this.rootScope.sideFiltersCtrl) this.rootScope.sideFiltersCtrl = this;
		if (!this.rootScope.commentsCtrl) this.rootScope.commentsCtrl = this;
	}

// aduce referinta la angular service/factory/filter
	getInjection(name) {
		if (!this.injections[name]) this.injections[name] = this.injector.get(name);
		if (this.injections[name]) return this.injections[name];
		console.error("Wrong reference: No injection exist for", name);
		return null;
	}


	tabClick($event) {
		if (!$event.target.hasAttribute('href')) return;
		let tabId = $event.target.getAttribute('href').substring(1);
		if (this.onTabClick) this.onTabClick(tabId);
	}

	setTableStyles(config) {
		if (!this.element) throw "Nu am referinta la elementul view-ului. Daca e un modal, initializeaza in vm.modal.rendered, si creaza referinta vm.element=$('#id-element')";
		let table = this.element.find('table[infinite-scroll]');
		table.addClass('table table-hover rnf_table');
		table.find('thead').addClass('rnf_table-head thead-light');
		table.parent().addClass('table-responsive');
		if (config.fixedHead) {
			$(document).ready(() => {
				angular.element('table[infinite-scroll]').floatThead(config.fixedHead);
				// angular.element('.k-aside__brand-aside-toggler').click(function () {
				// 	let interval = setInterval(function () {
				// 		angular.element('table[infinite-scroll]').floatThead('reflow');
				// 	}, 10);
				// 	setTimeout(function () {
				// 		clearInterval(interval);
				// 	}, 500);
				// });

			});
		}

	}

	// infinitScroll and search initialization
	/**
	 *
	 * @param config {Object} {params:{action:entity}, url: for api call, extraData: with keys for mapping response server to local variable this.data }
	 * config = {
	 * 			params: {action: 'timesheet'},
	 * 			search = initialization for search
	 * 			callback = functie de executat dupa fiecare download de date
	 *				url: "invoice_annex/" + this.data.invoice_id + "/entities-ajax",
	 *				totalsSelector: selector for totals panel
	 *				tableSelector: selector for items table
	 *				extraData: {totals: 'totals'}
	 *				fixedHead = { top: 75, zIndex: 90};
	 *	}
	 */
	getInfinitScroll(config) {
		if (!config) config = {};
		if (!this.entity) console.error('No entity property controller has. Set this.entity or config.controller !!!');
		let searchInit = config.search || {page: 1};
		if (config.params && config.params.action) searchInit.action = config.params.action;
		if (config.fixedHead == undefined) config.fixedHead = {top: 75, zIndex: 90};
		this.setTableStyles(config);
		angular.extend(searchInit, {page: 1, refresh: 0});
		this.searchInit = angular.copy(searchInit);
		this.infinitScroll = {};
		if (config.callback) this.infinitScroll.callback = config.callback.bind(this);
		// console.debug("rootScope._search", this.rootScope._search);
		this.infinitScroll.search = Object.assign(searchInit, this.rootScope._search);
		let queryParams = kvUtils.getAllQueryParams();
		angular.extend(this.infinitScroll.search, queryParams);
		if (config.params && config.params.action) this.infinitScroll.search.action = config.params.action;
		this.searchSrv = new (this.getInjection('searchInstance'))();
		this.searchSrv.entity = config.params && config.params.action ? config.params.action : this.entity;
		let cmdateSrv = this.getInjection('cmdate');
		if (!this.rest) this.rest = this.getInjection("resource").init(this.entity);
		let self = this;
		this.scope.$watch('vm.infinitScroll.search', (newValues, oldValues) => {
			// console.log("start search");
			let values = angular.copy(newValues);

			// reset page to 1 for start searching
			if (!this.infinitScroll.loading && this.infinitScroll.search.page > 1) {
				this.infinitScroll.dataLoaded = false;
				this.infinitScroll.search.page = 1;
				// console.log("is loading");
				return;
			}
			this.infinitScrollCallApi(config, values, oldValues);
		}, true);

		this.infinitScroll.readyToLoadMore = false;
		this.infinitScroll.setNgRepeatFinished = function ($last) {
			// console.log("last", $last, this.ngRepeatFinished );
		};
		this.infinitScroll.loadMore = function () {
			if (this.readyToLoadMore && !this.loading) {
				if (!this.search.page) {
					this.search.page = 1;
				}
				this.search.page = this.search.page + 1;
				this.readyToLoadMore = false;
				this.ngRepeatFinished = false;
				this.loading = true;
			}
		};
	}



	searchCallBackMapExtraData(response, config) {
		if (this.infinitScroll.search.page == 1 && config.extraData) {
			if (!this.data) this.data = {};
			Object.keys(config.extraData).forEach(key => {
				this.data[key] = response.data[key];
			});
		}
	}

	searchCallbackFillItemsList(paginator, newValues, oldValues) {
		if (oldValues.page == undefined || oldValues.page == newValues.page || oldValues.page > newValues.page) {
			this.infinitScroll.items = paginator.data;
		} else {
			let paginator_data = [];
			if($.type(paginator.data) == 'object') {
				paginator_data = $.map(paginator.data, function(value, index) {
					return [value];
				});
			} else if($.type(paginator.data) == 'array') {
				paginator_data = paginator.data
			}
			this.infinitScroll.items = this.infinitScroll.items.concat(paginator_data);
		}
		this.infinitScroll.total = paginator.total;
	}

	searchCallback(response, config, filters, newValues, oldValues) {
		// this.loader.remove();
		this.rootScope._search = filters;
		this.infinitScroll.isLoading = false;
		this.infinitScroll.loading = false;
		let paginator = null;
		if (response.$resolved && response.total !== undefined) {
			paginator = response;
		} else if (!response || !response.status || !response.data.paginator) return;
		this.searchCallBackMapExtraData(response, config);
		this.infinitScroll.dataLoaded = true;
		if (!paginator) paginator = response.data.paginator;
		this.searchCallbackFillItemsList(paginator, newValues, oldValues);
		if (this.infinitScroll.callback) this.infinitScroll.callback(response);
		// this.infinitScroll.last_page = (paginator.last_page == 1 ? 1 : paginator.last_page==paginator.current_page );
		if (!filters.page || filters.page < paginator.last_page) {
			this.infinitScroll.readyToLoadMore = true;
		}
		// console.log('page', filters.page);
		if (filters.page == 1) {
			$(window).trigger('resize');
		}
		if (!this.firstDataLoaded && this.element.find('table[infinite-scroll] tfoot').length) {
			this.firstDataLoaded = true;
			this.getInjection("$timeout")(() => {
				this.element.find('table[infinite-scroll]').DataTable({
					fixedHeader: {
						header: false,
						footer: true,
						footerOffset: -13
					},
					searching: false,
					bPaginate: false,
					paging: false,
					ordering: false,
					info: false,
					bLengthChange: false,
				});
			}, 0);
		}
	}

	infinitScrollCallApi(config, newValues, oldValues) {
		this.searchSrv.watch({
			search: this.infinitScroll.search,
			newValues: newValues,
			oldValues: oldValues,
			callback: filters => {
				if (config.params) angular.extend(filters, config.params);
				this.infinitScroll.isLoading = true;
				// console.log("call ", config, filters);
				this.rest.list({
					url: config.url || (this.entity + '/list'),
					params: filters
				}).then(response => this.searchCallback(response, config, filters, newValues, oldValues));
			},
			fixPager: function () {
				this.infinitScroll.search.page = 1;
			}
		});
	}

	resetFilters() {
		this.element.find('#daterange').val('');
		this.element.find('.dateTimePicker').val('');
		this.element.find('#generalSearch').val('');
		this.element.find("lookup").each((i, item) => {
			let placeholder = item.getAttribute('data-placeholder') || item.getAttribute('placeholder') || "";
			$(item).find('.select2-selection__rendered').html('<span class="select2-selection__placeholder">' + placeholder + '</span>');
		});
		this.element.find("select[ui-select2]").each((i, item) => {
			let placeholder = item.getAttribute('data-placeholder') || "";
			$(item).parent().find('.select2-selection__rendered').html('<span class="select2-selection__placeholder">' + placeholder + '</span>');
		});
		if (this.infinitScroll) this.infinitScroll.search = Object.assign({}, this.searchInit);
		else if (this.search && this.searchInit) this.search = Object.assign({}, this.searchInit);
		else if (this.search) this.search = {};
		// console.debug("reset filters", angular.copy(this.search));
		this.scope.$broadcast('dataLoaded');
		let $timeout = this.getInjection('$timeout');
		let selectPickers = this.element.find(".k_selectpicker");
		if (selectPickers.length) {
			$timeout(() => {
				selectPickers.each((i, selectPicker) => {
					$(selectPicker).selectpicker('refresh');
				});
			});
		}
	}

	/**
	 * Standard open modal function
	 * @param  {[type]} modalConfig [description]
	 * @return {[type]}             [description]
	 */
	openModal(modalConfig) {
		// standard configs e pus in app-init.js

		// do the rest
		this.modal = this.getInjection('$uibModal');
		this.http = this.getInjection("$http");
		if (!modalConfig.controllerAs) modalConfig.controllerAs = 'vm';
		let instance = this.modal.open(modalConfig);
		instance.opened.then(() => {
		});
		instance.rendered.then(() => {
		});
		instance.closed.then(() => {
		});
		return instance.result;
	}

	/**
	 *
	 * @param title
	 * @param message
	 * @param width
	 * @returns {'ok'!'cancel'}
	 */
	confirm(title, message, width) {
		let modalConfig = {
			component: "modalConfirm",
			resolve: {
				content: () => {
					return message;
				},
				title: () => {
					return title;
				},
				width: () => {
					return width;
				}
			}
		};
		let confirm = this.getInjection('$uibModal');
		return confirm.open(modalConfig).result;
	}

	alert(title, message, details, fullError, width) {
		let modalConfig = {
			component: "modalAlert",
			resolve: {
				title: () => {
					return title;
				},
				content: () => {
					return message;
				},
				details: () => {
					return details;
				},
				fullError: () => {
					return fullError;
				},
				width: () => {
					return width;
				},
			}
		};

		let alert = this.getInjection('$uibModal');
		return alert.open(modalConfig).result;
	}

//method to prepare data for send to backend. important to make angular.copy(this.data)
	//poate avea ca parametru data (altceva decat vm.data)
	prepareDataToSave() {
		return angular.copy(arguments.length ? arguments[0]: this.data);
	}

	/**
	 * Standard save method
	 * paote avea parametrii care sunt pasati si metodei prepareDataToSave()
	 * poate fi suprascrisa si apelata ca super din metoda ce o suprascrie: vm.super.save();
	 * @return {[type]} [description]
	 */
	save() {
		this.errors = undefined;
		let params = {data: this.prepareDataToSave.apply(this, arguments)};
		if (!this.isNewRecord) params.id = params.data[this.primaryKey];
		let action = this.isNewRecord ? "create" : "update";

		// saveUrl - allows to set custom url for the request
		let saveUrl = this.saveUrl ? this.saveUrl : null;
		if (saveUrl) params.url = saveUrl;

		KApp.block(this.element);

		this.rest[action](params).then(response => {
			KApp.unblock(this.element);
			if (response && (response.status || response.status === undefined)) {
				if (this.saveCallback) {
					this.saveCallback(response);
				} else {
					// Ensure that params.data and response.data are defined before accessing them
					let primaryKeyId = params.data && params.data[this.primaryKey]
						? params.data[this.primaryKey]
						: response.data && response.data[this.primaryKey];

					// redirectUrl - allows to set custom url for the redirect (after insert/update)
					window.location = this.redirectUrl ? this.redirectUrl : "/" + this.entity + "/" + primaryKeyId;
				}
			} else {
				this.showErrorMessages(response);
			}
		}).catch(error => {
			KApp.unblock(this.element);
			// Handle any errors that occur during the promise execution
			console.error('An error occurred:', error);
			this.showErrorMessages(error);
		});


	}

	showErrorMessages(response) {
		this.validator.markErrors(response.error, this.element);
		let messages = {};
		// console.log(response);
		Object.keys(response.error).forEach(field => {
			let errors = response.error[field];
			if (angular.isString(errors[0])) messages[field] = errors;
			else messages[field] = [this.trans('LANG.' + field) + ' ' + this.trans('LANG.MANDATORY')];
		});
		this.errors = messages;
		// console.warn(this.errors);

	}

	delete($index, msg, width, params) {
		msg = msg ? msg : this.getInjection('$filter')('trans')('LANG.ARE_YOU_SURE_DELETE');
		if (!this.primaryKey) throw "No primaryKey defined in vm controller!";
		return this.confirm('DELETE', msg, width)
			.then((response) => {
				if (response !== 'ok') return response;
				KApp.block(this.element);
				return this.rest.delete({
					params: {
						id: this.infinitScroll.items[$index][this.primaryKey],
						params: params
					},
					success: (response) => {
						KApp.unblock(this.element);
						if(typeof response.status !== 'undefined') {
							if(response.status == true) {
								this.infinitScroll.items.splice($index, 1);
								toastr.success(this.trans('LANG.OPERATION_SUCCESSFULLY'));
								return response;
							} else if (response.status == false) {
								this.alert(
									this.trans('LANG.ERROR') + (response.error.error_code ? response.error.error_code : ''),
									response.error.message,
									response.error.details,
									response.error.fullError
								);
							}
						} else {
							return response;
						}
					},
					error: (response) => {
						KApp.unblock(this.element);
						console.error(response);
						return response;
					}
				});
			});
	}

	selectPickerInit(options, selector) {
		let $timeout = this.getInjection("$timeout");
		let html = "";
		let element = angular.element(selector);
		if (options) {
			options.forEach((option) => {
				html += '<option ng-class="' + option.id + '" value="' + option.id + '" >' + option.name + '</option>';
			});
			element.html(html);
		}
		$timeout(() => element.selectpicker());
	}

	checkCollapseState(index) {
		if ($('#collapse_' + index).hasClass('show')) {
			$('.collapse_button_' + index).removeClass('fa fa-caret-down').addClass('fa fa-caret-right');
		} else {
			$('.collapse_button_' + index).removeClass('fa fa-caret-right').addClass('fa fa-caret-down');
		}
	}

	showMoreToggle(target) {
		let trans = this.getInjection('$filter')('trans');
		let element = $('#showMoreCollapse_' + target);
		if (element.length == 0) return;
		if (element.hasClass('expanded')) {
			element.removeClass('expanded');
			$('#showMoreCollapseToggler_' + target).html('<i class="fa fa-arrow-circle-down"></i> ' + trans('VIEW_MORE'));
		} else {
			element.addClass('expanded');
			$('#showMoreCollapseToggler_' + target).html('<i class="fa fa-arrow-circle-up"></i> ' + trans('VIEW_LESS'));
		}
	}

	clientSideValidate(errors, prefix, element) {
		errors = errors || {};
		prefix = prefix || 'data';
		element = element || this.element;
		if (!element || element.length == 0) console.error("metoda clientSideValidate necesita ca al doilea parameteru sa fie un jQuery HtmlElement exemplu: $('modal-content')");
		element.find("[required]").each((i, element) => {
			let ngModel = element.getAttribute("ng-model");
			if (!ngModel) console.error("Atributele required trebuie sa fie pus doar pe elemente care au ng-model");
			let field = ngModel.replace('vm.' + prefix + '.', '');
			if (!this[prefix][field]) {
				errors[field] = "required";
			}
		});
		element.find("[after]").each((i, element) => {
			let ngModel = element.getAttribute("ng-model");
			if (!ngModel) console.error("Atributele after trebuie sa fie pus doar pe elemente care au ng-model");
			let after = element.getAttribute('after');
			let field = ngModel.replace('vm.' + prefix + '.', '');

			if (this[prefix][field] < this[prefix][after]) {
				errors[field] = "after";
			}
		});
		this.validator.prefix = "vm." + prefix;
		if (Object.keys(errors).length == 0) return true;

		// console.debug('errors', errors);
		this.validator.markErrors(errors, element);
		return false;
	}

	setRelatedModelEntityAsPrimary($index, entity_type) {
		this.data[entity_type].forEach((item, index) => {
			item.is_primary = $index == index;
		});
	}

	newRelatedModelEntity(entity_type) {
		kvUtils.newRelatedModelEntityFor(entity_type, this.data);
	}

	deleteRelatedModelEntity($index, entity_type) {
		let is_primary = this.data[entity_type][$index].is_primary;
		this.data[entity_type].splice($index, 1);
		if (is_primary && this.data[entity_type].length) this.data[entity_type][0].is_primary = true;
	}

	showDocumentTemplates($entity, $entity_id) {
		this.openModal({
			templateUrl: "modal-document-templates",
			controller: 'documentTemplatesModalCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: {
					entity: $entity,
					entity_id: $entity_id
				}
			}
		}).then((response) => {
		});
	}


	loadFile(url, callback) {
		JSZipUtils.getBinaryContent(url,callback);
	}

	generateDocument(template_id, entity, entity_id, file_name) {
		let path = "/document_templates/" + entity + "/" + template_id;
		this.loadFile(path,(error,content) => {
			if (error) {
				this.alert(this.trans("LANG.ERROR") + ' 404', this.trans("LANG.FILE_NOT_FOUND"), this.trans("LANG.FILE_NOT_FOUND_DESCRIPTION"))
			} else {
				let rest = this.getInjection("resource").init('document_templates');
				rest.get({
					url: "document_templates/" + entity + "/" + entity_id + "/keys"
				}).then((res) => {
					let dataToRender = {};

					if(res.status == true && res.data) {
						dataToRender = res.data;

						let zip = new JSZip(content);
						let doc=new window.docxtemplater().loadZip(zip);

						doc.setData(dataToRender);

						try {
							doc.render()
						} catch (error) {
							let e = {
								message: error.message,
								name: error.name,
								stack: error.stack,
								properties: error.properties,
							}
							console.log(JSON.stringify({error: e}));
							// The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
							throw error;
						}

						let out=doc.getZip().generate({
							type:"blob",
							mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
						})

						saveAs(out, (file_name ? file_name : "Document.docx"))
					} else {
						this.alert("ERROR", this.trans("LANG.ERROR_DOWNLOADING_TEMPLATE"));
					}
				});


			}
		});
	}

	getControl(item) {
		let controlType = item.field_type, element;
		switch (controlType) {
			// case "checkbox":
			//     element = createElement('mt-checkbox', {ngModel: "entity.param_value", ngChange: 'vm.saveChange(entity)'});
			//     break;
			case "text":
				element = createElement('input', {class: "form-control", type: 'text', ngModel: "field.value"});
				break;
			case "numeral":
				element = createElement('input', {class: "form-control", type: 'text', ngModel: "field.value", castToNumber: "float"});
				break;
			case "options":
				element = createElement('select', {class: "form-control", ngModel: 'field.value'});
				let values = item.field_type_details.split(',');
				createElement("option", {value: ''}, '', element);
				values.forEach(value => {
					createElement("option", {value: value.trim()}, value.trim(), element);
				});
				break;
			case "date":
				element = createElement('datetimepicker', {type: "noTime", ngModel: "field.value"});
				break;
			case "link":
				element = element = createElement('input', {class: "form-control", type: 'text', ngModel: "field.value"});
				break;
		}
		if (!element) console.error("No definition found for ", controlType, "!");
		return element.outerHTML;
	}

	/**
	 * FIREBASE NOTIFICATIONS WEB
	 */
	
	/**
	 *
	 * gbarcun - disabled 30 oct 2023 - no longer used
	 *
	 * check if user.fcm_notification is null and popup request
	 * @return {[type]} [description]
	 */
	checkWebPush() {
		return;
		// if (!this.API.USER_IS_AUTHENTICATED) {
		// 	return;
		// }
		// if (!this.FCM.ENABLED) {
		// 	console.log('FCM is disabled');
		// 	return;
		// }
		// if (this.API.RECEIVE_FCM_NOTIFICATION === null) {
		// 	if (firebase && firebase.messaging.isSupported()) {
		// 		this.showWebPushModal();
		// 	}
		// }
		// if (this.API.RECEIVE_FCM_NOTIFICATION === true) {
		// 	if (!("Notification" in window)) {
		// 		console.log('FCM does not support this browser.');
		// 		return;
		// 	}
		// 	if (Notification.permission === "default") {
		// 		this.showWebPushNotification();
		// 	}
		// }
	}

	/**
	 * ask user if receives or not notifications;
	 * @return {[type]} [description]
	 */
	showWebPushModal() {
		let $context = this;
		this.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $context.filter("trans")("RECEIVE_NOTIFICATIONS");
				},
				title: () => {
					return $context.filter("trans")("IN_APP_NOTIFICATIONS");
				}
			}
		}).then(function () {
			let REST = $context.resource.init("user");
			REST.update({
				url:"profile",
				data: {
					"receive_fcm_notifications": true
				}
			}).then(function () {
				$context.showWebPushNotification();
			});
		});
	}

	/**
	 * store user.fcm_notifications = 0, never ask again
	 * @return {[type]} [description]
	 */
	declineWebPush() {
		let REST = this.resource.init('user');
		REST.update({
			url:"profile",
			data: {
				"receive_fcm_notifications": false
			}
		}).then(response => {
			console.log('Declinded push notification.');
		});
	}

	/**
	 * [saveToken description]
	 * @param  {[type]} token [description]
	 * @return {[type]}       [description]
	 */
	saveToken(token) {
		let REST = this.getInjection("resource").init('fcm');
		REST.post({
			url: "/fcm/register",
			data: {
				"token": token,
				"hardware_id": navigator.appVersion
			}
		}).then(response => {
			console.log('Saved push notification token.');
		});
	}

	/**
	 * listen to notifications and display
	 * @return {[type]} [description]
	 */
	showWebPushNotification() {
		if (firebase === undefined) {
			console.warn('Could not load firebase.');
			return;
		}
		if (!firebase.messaging.isSupported()) {
			console.warn('FCM does not support this browser.');
			return;
		}

		// check is firebase is already loaded
		if (firebase.apps.length) {
			return;
		}
		let $context = this;
		let config = {
			apiKey: this.FCM.API_KEY,
			authDomain: this.FCM.AUTH_DOMAIN,
			databaseURL: this.FCM.DATABASE_URL,
			projectId: this.FCM.PROJECT_ID,
			storageBucket: this.FCM.STORAGE_BUCKET,
			messagingSenderId: this.FCM.MESSAGING_SENDER_ID,
			appId: this.FCM.APP_ID
		};
		firebase.initializeApp(config);

		let messaging = firebase.messaging();
		messaging
			.requestPermission()
			.then(function (response) {

				// get the token in the form of promise
				messaging.getToken()
					.then(function(token) {
						$context.saveToken(token);
					})
					.catch(function (err) {
						console.log("Unable to get permission to notify.", err);
					});
			})
			.catch(function (err) {
				console.log("Unable to get permission to notify.", err);
			});

		// show message as toastr
		messaging.onMessage(function(payload) {
			toastr.info(
				payload.notification.body,
				payload.notification.title, 
				{
					"closeButton": true,
					"debug": false,
					"newestOnTop": false,
					"progressBar": true,
					"positionClass": "toast-top-right",
					"preventDuplicates": false,
					"showDuration": "300",
					"hideDuration": "1000",
					"timeOut": "10000",
					"extendedTimeOut": "1000",
					"showEasing": "swing",
					"hideEasing": "linear",
					"showMethod": "fadeIn",
					"hideMethod": "fadeOut"
				}
			);
		});
	}
	/**
	 * END FIREBASE NOTIFICATIONS WEB
	 */
}
