kv.controller('addEditDirectoryCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'file_storage', 'id', $scope, params);

	vm.directory_name = params.directory_name;
	vm.save = function () {
		KApp.block('#documents_storage_directory-name');
		let action = params.directory_id ? 'rename' : 'create';
		if (action === 'create') {
			vm.rest.create({
				url: 'files/' + params.entity + '/' + params.entity_id + '/' + action + '_folder',
				data: {
					parent_id: params.parent_id,
					entity_id : params.entity_id,
					folder_name: vm.directory_name,
					directory_id: params.directory_id
				}
			}).then(function(response){
				KApp.unblock('#documents_storage_directory-name');
				vm.saveCallback(response, vm.modal);
			});
		} else {
			vm.rest.update({
				url: 'files/' + params.entity + '/' + params.entity_id + '/' + action + '_folder/' + params.directory_id,
				data: {
					parent_id: params.parent_id,
					entity_id: params.entity_id,
					folder_name: vm.directory_name,
					directory_id: params.directory_id
				}
			}).then(function (response) {
				KApp.unblock('#documents_storage_directory-name');
				vm.saveCallback(response, vm.modal);
			});
		}
	};

	vm.saveCallback = saveCallback;
	return vm;
});
